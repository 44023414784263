.slider {
    position: relative;
    min-height: 60px;
    width: 100%;
    @include bdown(xs) {
        min-height: 0px;
    }
}
.slick-slide {
    &:focus {
        outline: none;
    }
}
.slick-track {
    display: flex;
}
.slider-instance {
    &.init {
        opacity: 0;
        height: 160px;
    }
}
.slick-slide {
    height: inherit;
    a:focus {
        outline: none;
    }
}
ul.slick-dots {
    display: inline-flex;
    li {
        list-style-type: none;
    }
}
.slick-arrow {
    text-decoration: none;
    &.slick-arrow-alt {
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(40);
        height: rem(40);
        background-color: $violet-light;
        border: 1px solid $violet-light;
        color: $white;
        border-radius: 50%;
        @include sfs(20);
        @include transition;
        &.slick-prev {
            left: 1%;
        }
        &.slick-next {
            right: 1%;
        }
        &:hover {
            filter: brightness(90%);
        }
    }
    &.default-arrow {
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(72);
        height: rem(72);
        border: 1px solid $gray;
        border-radius: 50%;
        color: $gray;
        @include sfs(29);
        @include transition;
        &.slick-prev {
            left: 1%;
        }
        &.slick-next {
            right: 1%;
        }
        &:hover {
            filter: brightness(90%);
        }
    }
}
%default-arrows {
    .slick {
        &-arrow {
            position: absolute;
            z-index: 10;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(72);
            height: rem(72);
            border: 1px solid $gray;
            border-radius: 50%;
            color: $gray;
            @include sfs(29);
            @include transition;
            &.slick-prev {
                left: 1%;
            }
            &.slick-next {
                right: 1%;
            }
            &:hover {
                filter: brightness(90%);
            }
        }
    }
}
.preloaderOverlay {
    height: 160px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.preloader {
    position: absolute!important;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 100px;
    transform: translate(-50%, -50%);
    background-image: url(../../images/logo-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    animation: loader-animation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}
@keyframes loader-animation {
    from {
      transform: translate(-50%, -50%) rotate(0deg) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-50%, -50%) rotate(180deg) scale(.8);
      opacity: .8;
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) scale(1);
      opacity: 1;
    }
}
.top-slider {
    width: 100%;
    position: relative;
    border-bottom: 1px solid $white;
    .slick {
        &-arrow {
            position: absolute;
            z-index: 10;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(72);
            height: rem(72);
            border: 1px solid $white;
            border-radius: 50%;
            color: $white;
            @include sfs(29);
            @include transition;
            @include bdown(md){
                width: 40px;
                height: 40px;
            }
            &.slick-next {
                right: 3%;
            }
            &.slick-prev {
                left: 3%;
            }
            &:hover {
                background-color: rgba($color: $white, $alpha: .1);
            }
        }
        &-dots {
            position: absolute;
            width: auto;
            bottom: 1rem;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            padding: 0 1em;
            li {
                margin: 0 .25em;
                a {
                    display: block;
                    width: 1.333rem;
                    height: 1.333rem;
                    border: 1px solid #fff;
                    border-radius: 50%;
                }
                &.slick-active {
                    a {
                        background-color: #fff;
                        transform: scale(1.3);
                    }
                }
            }
        }
        &-track {
            height: inherit;
        }
        &-list {
            height: inherit;
        }
    }
    .slider-item {
        &.half-dimmed {
            &:after {
                background-color: transparent;
                background-image: linear-gradient(to right, rgba(0, 0, 0, .84) 0, rgba(0, 0, 0, 0) 70%);
            }
        }
    }
    .slider-item {
        &.clear {
            &:after {
                content: none;
            }
        }
    }
    .slider-item {
        position: relative;
        background-size: cover !important;
        background-position: center top;
        background-repeat: no-repeat;
        max-height: 100vh;
        height: inherit;
        &:after {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #021A2E, $alpha: .52);
        }
        // &.position-left {
        //     &:after {
        //         display: block;
        //         content: "";
        //         left: 0;
        //         width: 100%;
        //         top: 0;
        //         height: 100%;
        //         background-image: linear-gradient(to right, rgba(0, 0, 0, .99), rgba(0, 0, 0, .42) 50%, transparent);
        //     }
        // }
        &.position-right {
            // &:after {
            //     display: block;
            //     content: "";
            //     left: 0;
            //     width: 100%;
            //     top: 0;
            //     height: 100%;
            //     background-image: linear-gradient(to right, rgba(0, 0, 0, .99), rgba(0, 0, 0, .42) 50%, transparent);
            //     @include bup(md){
            //         background-image: linear-gradient(to left, rgba(0, 0, 0, .99), rgba(0, 0, 0, .42) 50%, transparent);
            //     }
            // }
            .description .inner {
                &.empty {
                    display: none;
                }
                @include bup(md){
                    right: 0;
                }
                @include bbetween(md, lg){
                    right: 15%;
                    left: auto;
                }
            }
        }
    }
    
    
    .description {
        position: absolute;
        display: block;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        height: 100%;
        max-width: 1500px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        z-index: 15;
        @media (max-width: 1700px){
            padding-left: 10%;
            padding-right: 10%;
        }
        .inner {
            position: absolute;
            @include bup(md){
                max-width: 655px;
            }
            // padding: 8% 0;
            color: #fff;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .slider-title-block {
            .title {
                @include font-count(22, 60);
                font-weight: 700;
                line-height: 1.2;
                text-transform: capitalize;
            }
            .subtitle {
                @include font-count(18, 30);
                font-weight: 700;
                letter-spacing: .1em;
                line-height: 1.2;
                margin-bottom: 2em;
                text-transform: uppercase;
            }
            p {
                font-size: rem(26);
            }
        }
    }
    &.content-based{
        .description {
            position: relative;
            left: 0;
            transform: none;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: space-between;
            padding-top: 6rem;
            padding-bottom: 6rem;
            height: auto;
            .inner {
                transform: none;
                position: static;
                top: auto;
                transform: none;
            }
        }
        &.small {
            .description {
                padding-top: 3rem;
                padding-bottom: 3rem;
            }
        }
        &.large {
            .description {
                padding-top: 12rem;
                padding-bottom: 12rem;
            }
        }
    }
    @include bup(md){
        .slider-button{
            &:first-child {
                margin-right: rem(16);
            }
        }
    }
    @include bdown(md){
        .slider-item {
            min-height: 20vh;
        }
        &.content-based {
            .slider-item {
                min-height: 0;
            }
        }
        .slider-item {
            .description {
                padding-left: 15%;
                padding-right: 15%;
                .slider-buttons-block {
                    margin-top: 5px;
                    .btn {
                        font-size: 10px;
                        &.play-video {
                            &:before {
                                width: 1.5rem;
                                height: 1.05813953489rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
.middle-slider {
    width: 100%;
    position: relative;
    .slick {
        &-arrow {
            position: absolute;
            z-index: 10;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(72);
            height: rem(72);
            border: 1px solid $white;
            border-radius: 50%;
            color: $white;
            @include sfs(29);
            @include transition;
            &.slick-next {
                right: 3%;
            }
            &.slick-prev {
                left: 3%;
            }
            &:hover {
                background-color: rgba($color: $white, $alpha: .1);
            }
        }
        &-dots {
            position: absolute;
            width: auto;
            bottom: 1rem;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            padding: 0 1em;
            li {
                margin: 0 .25em;
                a {
                    display: block;
                    width: 1.333rem;
                    height: 1.333rem;
                    border: 1px solid #fff;
                    border-radius: 50%;
                }
                &.slick-active {
                    a {
                        background-color: #fff;
                        transform: scale(1.3);
                    }
                }
            }
        }
        &-track {
            height: inherit;
        }
        &-list {
            height: inherit;
        }
    }
    .slide {
        position: relative;
        .container {
            position: relative;
            z-index: 3;
        }
        &:after {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, .84));
        }
    }
    .slide-text {
        max-width: 500px;
        font-size: rem(50);
        color: $white;
        font-weight: 700;
        @include letter-spacing(-20);
        line-height: 1;
        display: inline-block;
        text-align: center;
        span {
            font-size: rem(81);
            font-family: $ak;
            color: $yellow;
            font-weight: 400;
        }
    }
}
.pf-slider {
    padding-left: calc(2% + 4rem);
    padding-right: calc(2% + 4rem);
    .slide {
        color: $white;
        display: flex;
        margin: 0 3px;
        & > a {
            color: $white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include transition;
            &:hover {
                filter: brightness(90%);
            }
        }
        h5 {
            color: inherit;
            font-weight: 700;
        }
        p {
            font-size: rem(16);
        }
        .pf-slide-top {
            padding: 2rem;
        }
    }
    @extend %default-arrows;
}
.programs-slider {
    background-color: #524486;
    @include bup(md){
        height: 100%;
    }
    .slide {
        height: inherit;
    }
    .slide-top {
        img {
            width: 100%;
        }
    }
    .slide-bottom {
        padding: 1rem 2rem;
        color: $white;
    }
    .slick-dots {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        bottom: rem(10);
        right: rem(10);
        li {
            margin: 0 1px;
        }
        .slick-dot {
            color: rgba($color: $white, $alpha: .3);
            i {
                transform: scale(.8);
            }
        }
        .slick-active {
            .slick-dot {
                color: #AB8E66;
                i {
                    transform: scale(1);
                }
            }
        }
    }
    .slide-text {
        margin-bottom: 1rem;
    }
    .slide-cr {
        font-family: $ak;
        font-size: rem(36);
    }
}
.fyc-slider {
    padding-left: calc(2% + 4rem);
    padding-right: calc(2% + 4rem);
    .slide {
        padding: 0 20px rem(60);
    }
    @extend %default-arrows;
    .info-block {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-bottom: rem(10) solid $violet;
        margin-top: -4rem;
        background-color: $white;
        position: relative;
        z-index: 3;
        box-shadow: 0 rem(10) rem(60) rgba($color: $black, $alpha: .1);
        h6 {
            color: $white;
            background-color: $green;
            text-align: center;
            padding: 1rem;
            font-size: rem(16);
            font-family: $karla;
        }
        h5 {
            color: $violet;
            text-align: center;
            padding: 0 1rem;
            font-size: rem(20);
        }
        p {
            font-size: rem(16);
            padding: 0 1rem 1rem;
            line-height: 1.25;
        }

    }
}
.partners-slider {
    padding-left: calc(2% + 4rem);
    padding-right: calc(2% + 4rem);
    @extend %default-arrows;
    .slick-slide {
        // height: 70px;
        padding-left: 7px;
        padding-right: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.synced-slider {
    max-width: 100%;
    &-nav,
    &-for {
        max-width: 100%;
        .inner {
            padding: 5px;
            height: 100%;
        }
    }
    &-for {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        padding-bottom: 4px;
    }
    &-nav {
        padding-left: calc(2% + 2rem);
        padding-right: calc(2% + 2rem);
        .slide {
            padding-left: 2px;
            padding-right: 2px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                box-shadow: 0 3px 6px rgba($color: $black, $alpha: .16);
            }
        }
    }
    .slick-arrow {
        width: auto;
        height: auto;
        color: $yellow;
        background-color: transparent;
        border: none;
        &.slick-prev {
            &:before {
                content: '\f060';
            }
        }
        &.slick-next {
            &:before {
                content: '\f061';
            }
        }
    }
}
.fullwidth-slider {
    .slide > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 280px;
    }
}
.img-slider {
    &.alt {
        .slide {
            position: relative;
            &:after {
                content: "";
                position: absolute;
                display: block;
                right: 0;
                width: 70%;
                top: 0;
                height: 100%;
                background-image: linear-gradient(to left, rgba(0,0,0,.84), rgba(0,0,0,0));
            }
        }
        .container {
            position: relative;
            z-index: 5;
        }
    }
    .slide {
        min-height: 33vh;
        background-repeat: no-repeat;
        background-size: cover;
        img {
            max-height: 300px;
        }
    }
    color: $white;
    h1, h2, h3, h4, h5 {
        color: inherit;
    }
    h5 {
        font-size: rem(50);
    }
    h4 {
        font-size: rem(81);
        font-family: $ak;
        color: $yellow;
        font-weight: normal;
    }
    p {
        font-size: rem(24);
        font-weight: 700;
        margin-bottom: 0;
    }
}




.wwd-slider {
    padding-left: 10%;
    padding-right: 10%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 4rem;
    .slider-item {
        padding: 0 15px;
    }
    .slick {
        &-arrow {
            position: absolute;
            z-index: 10;
            bottom: 1rem;
            color: $gray;
            font-size: rem(30);
            &.slick-next {
                left: calc(50% + 1rem);
            }
            &.slick-prev {
                right: calc(50% + 1rem);
            }
            &:hover {
                color: $yellow;
            }
        }
    }
    .inner {
        text-decoration: none;
        position: relative;
        display: block;
        overflow: hidden;
        img {
            display: block;
        }
        .wwd {
            &--info {
                z-index: 10;
                position: relative;
                background-color: #fff;
                @include transition;
                padding: 1rem;
            }
            &--text,
            &--title {
                text-align: center;
            }
            &--text {
                font-size: rem(19);
            }
            &--title {
                font-size: rem(30);
                font-weight: 700;
            }
        }
        &:hover {
            .wwd--info {
                background-color: $yellow;
            }
            .sliding {
                top: 0;
            }
        }
    }
}
.info-slider {
    .slick {
        &-arrow {
            position: absolute;
            z-index: 10;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            color: $yellow;
            @include sfs(29);
            &.slick-next {
                right: 3%;
            }
            &.slick-prev {
                left: 3%;
            }
            &:hover {
                filter: brightness(90%);
            }
        }
        &-track {
            height: inherit;
        }
        &-list {
            height: inherit;
        }
        &-slide {
            height: inherit;
        }
    }
    &.alt {
        .banner {
            padding-top: 5rem;
            padding-bottom: 5rem;
            &:before {
                position: absolute;
                display: block;
                content: "";
                left: 0;
                width: 100%;
                top: 0;
                height: 100%;
                background-color: rgba($color: $black, $alpha: .25);
                z-index: 1;
            }
            .container {
                position: relative;
                z-index: 5;
                h2 {
                    @include fluid-font(60px);
                    line-height: 1;
                    margin-bottom: 0;
                }
                p {
                    font-size: rem(26);
                }
            }
        }
    }
    @include bup(md){
        .basis-60 {
            margin-right: 5%;
        }
    }
    @include bdown(md) {
        .inner, .banner {
            padding-left: 2%;
            padding-right: 2%;
        }
    }
}
.testimonials-slider {
    
    .slick-track, .slick-list {
        display: flex !important;
        // justify-content: center;
    }
    .slick-track {
        justify-content: center;
    }
    .slick-slide {
        // display: flex;
        height: 100%;
    }
    .inner {
        padding: 4em 2em 5em;
        position: relative;
        height: 100%;
    }
    .testimonial {
        padding: 8%;
        position: relative;
        background-color: $white;
        height: 100%;
        border-radius: 5px;
        position: relative;
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -1em;
            right: 1.5em;
            width: 5em;
            height: 3.5em;
            background-image: url(../../images/quotes.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
        p {
            margin-bottom: 0;
        }
        .ts {
            &-text {
                font-size: rem(20);
                margin-bottom: 1em;
            }
            &-author {
                font-weight: 700;
                font-size: rem(22);
            }
            &-position {
                color: #5F6A75;
            }
        }
        img {
            margin-bottom: 2rem
        }
        p {
            @include fluid-font(19px);
        }
    }
    .slick-prev.icon,
    .slick-next.icon {
        position: absolute;
        transform: translateY(-50%);
        top: 40%;
        color: #D2D2D2;
        z-index: 100;
        color: $gray;
        &:before {
            @include transition;
            display: inline-block;
            text-align: center;
            font-size: rem(38);
        }
        &:hover {
            // color: $blue-alt;
        }
    }
    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 0;
    }
    .slick-dots {
        text-align: center;
    }
    @include bup(md) {
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 5%;
        padding-right: 5%;
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }
    @include bdown(md) {
        padding-top: 25px;
        padding-bottom: 0;
        .inner {
            padding-left: 5%;
            padding-right: 5%;
            margin-left: 5%;
            margin-right: 5%;
        }
    }
}
.our-news {
    background-color: $yellow;
    a {
        text-decoration: none;
    }
    .slick-slide {
        height: inherit;
    }
    .decorated {
        &:after {
            background-color: #fff;
        }
    }
    .our-news-card {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
        &-inner {
            padding: .5rem 1rem;
            flex-grow: 1;
            position: relative;
            background-color: $black;
        }
        &-title {
            @include fluid-font(20px);
            margin-top: .5rem;
            padding-bottom: .5rem;
            margin-bottom: .5rem;
            font-size: rem(24);
            line-height: normal;
            font-weight: 600;
            color: $white;
            &:after {
                display: block;
                content: "";
                width: 70px;
                height: 2px;
                background-color: $yellow;
                margin-top: .5rem;
            }
        }
        &-date {
            color: #A59FA2;
            display: inline-block;
            font-size: rem(17);
        }
        &-media-wrapper {
            position: relative;
            height: 0;
            padding-bottom: (9 * 100% / 16);
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &:hover {
            .btn-container {
                span {
                    background-color: #fff;
                }
            }
        }
    }
    .news-slider {
        // padding-top: 5rem;
        max-width: 1360px;
    }
    .link-more {
        color: #fff;
        @include bdown(md){
            display: block;
            text-align: right;
        }
    }
    .slick-arrow {
        font-size: 1rem;
        width: 2.5rem;
        height: 2.5rem;
        border: 2px solid #999999;
        color: #999999;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        &:hover {
            color: #fff;
        }
        &.slick-prev {
            top: 0;
            right: 5rem;
        }
        &.slick-next {
            top: 0;
            right: 2rem;
        }
    }
}
.sp-slider {
    .slick {
        &-arrow {
            position: absolute;
            z-index: 10;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            color: $yellow;
            @include sfs(29);
            &.slick-next {
                right: 3%;
            }
            &.slick-prev {
                left: 3%;
            }
            &:hover {
                filter: brightness(90%);
            }
        }
        &-track {
            height: inherit;
        }
        &-list {
            height: inherit;
        }
        &-slide {
            height: inherit;
        }
    }
    @include bup(md){
        &-container {
            max-width: 50%;
        }
    }
}
.double-slider {
    padding-left: calc(2% + 4rem);
    padding-right: calc(2% + 4rem);
    &-container {
        background-color: #EAEEE8;
    }
    .slide {
        padding: 0 20px;
        position: relative;
        &:after {
            content: "";
            display: block;
            position: absolute;
            z-index: 3;
            left: 20px;
            right: 20px;
            top: 0;
            height: 100%;
            background-image: linear-gradient(to top, rgba(0, 0, 0, .68) 0, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100% );
        }
    }
    @extend %default-arrows;
    img {
        width: 100%;
    }
    .ds-text {
        @include font-count(20, 47);
        font-weight: 700;
        color: $white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 1;
        text-align: center;
        z-index: 5;
    }
}


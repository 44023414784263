.showcase {
	background-color: #f4f2f7;
	background-image: url(../../images/icons/fc-bg.svg);
	background-repeat: no-repeat;
	background-position: 9% 36%;
	h3 {
		font-family: $ak;
		font-size: rem(50);
		color: $violet-light;
	}
	.cta {
		column-gap: 2rem;
		font-size: rem(15);
		color: $base-alt;
		font-weight: 700;
	}
	&-promo {
		display: flex;
		justify-content: center;
		&.alt {
			span {
				display: block;
			}
		}
	}
	&-promoimg {
		padding-right: 10px;
		img {
			height: rem(70);
		}
	}

	&-promotext {
		font-size: rem(22);
		font-weight: 700;
		line-height: 1;
		span {
			padding-right: 0.5rem;
			&:first-child {
				font-family: $ak;
				position: relative;
				color: $violet-light;
				font-size: rem(50);
				font-weight: 400;
			}
			&:nth-child(2) {
				&:not(:last-child) {
					color: $base-alt;
				}
			}
			&:last-child {
				color: $green;
				padding-right: 0;
			}
		}
		a {
			color: $green;
			font-weight: 700;
		}
		&.alt {
			span {
				display: block;
				&:last-child {
					color: $base-alt;
					padding-right: 0;
				}
			}
		}
	}
	&-gallery {
		display: grid;
		&.layout-1 {
			grid-template-columns: 2.5fr 1fr;
			grid-template-rows: auto auto;
			grid-template-areas:
				"top-img aside-img-1"
				"top-img aside-img-2";
		}
		&.layout-2 {
			grid-template-columns: 0.8fr 1.2fr;
			grid-template-rows: 1.2fr 0.8fr;
			grid-template-areas:
				"top-img top-img"
				"aside-img-1 aside-img-2";
		}
		grid-gap: 10px;
		img {
			width: 100%;
			min-height: 100%;
			object-fit: cover;
		}
		.top-img {
			grid-area: top-img;
		}
		.aside-img-1 {
			grid-area: aside-img-1;
		}
		.aside-img-2 {
			grid-area: aside-img-2;
		}
	}
	.contact-links {
		margin-top: 1rem;
		padding-top: 0.5rem;
		border-top: 2px solid #6a6b69;
		font-family: $krub;
		font-weight: 500;
		font-size: rem(14);
		i {
			color: $yellow;
			margin-right: 1rem;
		}
		a {
			color: #6a6b69;
			&:hover {
				color: $yellow;
			}
			&:first-of-type {
				margin-right: 1rem;
			}
		}
	}
	&.mirrored {
		background-color: #e4eae3;
		& > .container > .row {
			flex-direction: row-reverse;
		}
	}
	&-navigation {
		&-link {
			display: flex;
			align-items: center;
			justify-content: center;
			@include transition;
			&:hover {
				filter: brightness(110%);
			}
			img {
				position: relative;
				z-index: 3;
				display: inline-block;
			}

			span {
				background-color: $green;
				text-align: center;
				font-family: $karla;
				padding: 1.5rem;
				color: $white;
				border-radius: rem(20);
				transform: translateX(30px);
				font-size: rem(16);
				max-width: 100%;
				min-height: rem(63);
				display: inline-flex;
				align-items: center;
				white-space: nowrap;
				@include bdown(md) {
					font-size: rem(20);
					min-height: 0;
				}
				&:after {
					display: inline-block;
					content: "\f061";
					font-family: $awesome;
					font-weight: 900;
					padding-right: 1rem;
					padding-left: 1rem;
				}
			}
			@include bdown(md) {
				img {
					max-width: 25%;
				}
				span {
					white-space: initial;
				}
			}
		}
		&-row {
			.col {
				margin-bottom: 1rem;
				&:last-of-type {
					margin-bottom: 2rem;
				}
			}
			@include bdown(lg) {
				display: block;
			}
		}
	}
}
.about-iconblock {
	&.alt-bg {
		background-color: #ece9f2;
	}
	.col {
		display: flex;
		img {
			margin-right: 0.5rem;
		}
		h5 {
			color: $violet;
		}
		&:nth-child(2) {
			border-left: 1px solid #e6e6e6;
			border-right: 1px solid #e6e6e6;
		}
	}
}
.in-numbers,
.in-numbers-alt {
	position: relative;
	text-align: center;
	&:after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #624c9f, $alpha: 0.9);
	}
	.container {
		position: relative;
		z-index: 5;
	}
	h2 {
		color: $white;
	}
	.num {
		font-family: $ak;
		font-size: rem(100);
		color: $yellow;
		line-height: 1;
		@include letter-spacing(-20);
		& + p {
			font-size: rem(18);
			color: $white;
			max-width: 80%;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
.in-numbers-alt {
	.col {
		display: flex;
		align-items: center;
		justify-content: center;
		@include bdown(lg) {
			flex-direction: column;
		}
		@include bdown(md) {
			flex-direction: row;
		}
		p {
			margin-bottom: 1rem;
			font-weight: 700;
			font-size: rem(24);
			color: $white;
			padding-right: 1rem;
			text-align: left;
			display: inline;
			max-width: 181px;
		}
	}
}
.our-programs {
	@include bup(md) {
		.slider {
			max-width: 50%;
		}
	}
	.col .col img {
		width: 100%;
	}
}

.text-img {
	h6 {
		font-size: rem(20);
		color: #707070;
	}
	h2 {
		color: $violet;
	}
	h4 {
		font-size: rem(33);
		font-family: $ak;
		color: $violet-light;
	}
	& > li {
		padding: 2rem 0;
	}
	.img-container {
		display: inline-block;
		img {
			display: block;
		}
	}
	.contact-links {
		margin-top: 1rem;
		padding-top: 0.5rem;
		border-top: 2px solid #6a6b69;
		font-family: $krub;
		font-weight: 500;
		font-size: rem(14);
		i {
			color: $yellow;
			margin-right: 1rem;
		}
		a {
			color: #6a6b69;
			&:hover {
				color: $yellow;
			}
			&:first-of-type {
				margin-right: 1rem;
			}
		}
	}
	ul {
		margin-bottom: 1rem;
		font-weight: 700;
		color: $base-alt;
		li {
			// display: inline-block;
			display: block;
			margin-right: 1rem;
			&:before {
				font-family: $awesome;
				color: $yellow;
				padding-right: 0.5rem;
				content: "\f111";
				font-weight: 900;
				font-size: 0.6rem;
				transform: translateY(-2px);
				display: inline-block;
			}
			&:last-of-type {
				margin-right: 0;
			}
		}
	}
	ul.program-details {
		font-weight: normal;
		color: inherit;
		& > li {
			list-style-type: none;
			display: flex;
			&:before {
				content: none;
			}
			& > div {
				&:first-of-type {
					color: $violet;
					font-size: rem(29);
					font-weight: 700;
					flex: 0 0 20%;
				}
				&:last-of-type {
					color: $base;
					padding-top: 0.5rem;
				}
			}
		}
	}
	&.switching {
		& > li {
			&:nth-child(odd) {
				background-color: #f6f5f9;
				background-image: url(../../images/icons/fc-bg.svg);
				background-repeat: no-repeat;
				background-position: -2% 10%;
				.img-container {
					border-right: rem(41) solid $green;
				}
			}
			&:nth-child(even) {
				background-color: #e4eae3;
				background-image: url(../../images/icons/fc-bg-alt.svg);
				background-repeat: no-repeat;
				background-position: 102% 10%;
				& > .container > .row {
					flex-direction: row-reverse;
				}
				.img-container {
					border-left: rem(41) solid $violet;
				}
			}
		}
	}
	&.alt {
		& > li {
			padding: 0;
			&.pv-2 {
				padding-top: 2rem !important;
				padding-bottom: 2rem !important;
			}
			&:nth-child(odd) {
				& > .container > .row > .col {
					@include bup(md) {
						&:first-child {
							padding-left: 10%;
						}
					}
				}
			}
			&:nth-child(even) {
				& > .container > .row > .col {
					@include bup(md) {
						&:first-child {
							padding-right: 10%;
						}
					}
				}
			}
			& > .container > .row > .col {
				&:first-child {
					padding-top: 2rem;
					padding-bottom: 2rem;
				}
			}
		}
		.img-container.bordered {
			border: none !important;
		}
	}
	.ww.step-ol {
		ol {
			list-style-type: none;
			counter-reset: elementcounter;
			padding-left: 0;
		}

		li {
			list-style-type: none;
			position: relative;
			padding-left: 6rem;
			margin-bottom: 1rem;
			&:before {
				content: "Step " counter(elementcounter) ". ";
				counter-increment: elementcounter;
				font-weight: bold;
				position: absolute;
				top: -5px;
				left: 0;
				color: $violet;
				font-size: rem(29);
			}
		}
	}
}

.parts-banner {
	background-repeat: no-repeat;
	background-size: auto 100%;
	position: relative;
	overflow: hidden;
	color: $white;
	&:before {
		content: "";
		background-image: linear-gradient(
			to right,
			rgba(98, 76, 159, 0) 0,
			rgba(98, 76, 159, 1) 60%,
			rgba(98, 76, 159, 1) 100%
		);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	&.alt {
		&:before {
			background-image: linear-gradient(
				to right,
				rgba(154, 172, 144, 0) 0,
				rgba(154, 172, 144, 1) 60%,
				rgba(154, 172, 144, 1) 100%
			);
		}
	}
	.container {
		position: relative;
		z-index: 5;
	}
	h2 {
		color: $white;
	}
	.moving-spheres {
		position: absolute;
		top: 0;
		right: 20%;
		z-index: 3;
		animation: moving-spheres;
		animation-duration: 30s;
		animation-iteration-count: infinite;
		animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
		// animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
		// animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
	}
}
@keyframes moving-spheres {
	0% {
		top: 0;
		right: 20%;
		transform: scale(1) rotate(0);
	}
	48% {
		top: 10%;
		right: 25%;
		transform: scale(0.5) rotate(180deg);
	}
	52% {
		top: 10%;
		right: 25%;
		transform: scale(0.5) rotate(180deg);
	}
	100% {
		top: 0;
		right: 20%;
		transform: scale(1) rotate(360deg);
	}
}

.latest-news {
	background-color: #f4f2f7;
	.subscribe-block {
		h4,
		p {
			max-width: 90%;
		}
		h4 {
			font-size: rem(31);
			color: $violet;
		}
	}
	.news-item {
		display: flex;
		margin-bottom: 1rem;
		h4,
		h6 {
			color: $white;
		}
		h6 {
			font-family: $karla;
			font-size: rem(16);
			margin-bottom: 0;
		}
		h4 {
			font-size: rem(27);
			margin-bottom: 0.5rem;
		}
		p {
			margin-bottom: 0;
		}
		.media-wrapper {
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&--media {
			max-width: 300px;
			flex: 1 0 300px;
			img {
				display: block;
			}
		}
		&--info {
			background-color: $green;
			flex: 1 1 auto;
			border-right: rem(40) solid $violet;
			padding: 1rem 1rem 1rem 3rem;
			display: block;
			@include transition;
			&:hover {
				background-color: $violet;
				p {
					color: $white;
				}
			}
		}
	}
	.collapse-lg {
		@include bdown(lg) {
			display: block;
			.subscribe-block {
				max-width: 50%;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 1rem;
			}
		}
		@include bdown(md) {
			.subscribe-block {
				max-width: 100%;
			}
			.news-item {
				display: block;
				.news-item--media {
					flex: 1 1 auto;
					max-width: 100%;
				}
			}
		}
	}
}
.about-welcome {
	h4 {
		color: $violet;
		font-size: rem(33);
	}
}

.about-staff-board {
	&--left {
		@include bup(md) {
			margin-right: 7%;
		}
		img {
			width: 100%;
			display: block;
		}
		.info {
			background-color: $violet;
			color: $white;
			padding: 2rem;
			h6 {
				color: $white;
				font-family: $ak;
				font-size: rem(32);
			}
			p {
				font-family: $karla;
				font-weight: 700;
			}
		}
	}
	&--right {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		.block {
			background-color: #eaeee8;
			padding: 2rem;
			&:first-child {
				margin-bottom: 2rem;
			}
		}
	}
	@include bdown(md) {
		&--right {
			height: auto;
		}
	}
}
.timeline {
	.item {
		display: flex;
		&:last-of-type {
			.timeline-info {
				padding-bottom: 0;
				&:after {
					content: none;
				}
			}
		}
	}
	&-year {
		max-width: 30%;
		flex: 0 0 30%;
		align-self: flex-start;
		text-align: right;
		span {
			display: inline-flex;
			font-size: rem(50);
			font-weight: 700;
			color: $green;
			align-items: center;
			width: 60%;
			justify-content: space-between;
			&:after {
				content: "";
				width: 40%;
				height: rem(22);
				background-color: $green;
			}
		}
	}
	&-info {
		background-color: #eaeee8;
		display: flex;
		align-items: center;
		border-left: rem(22) $green solid;
		padding-bottom: rem(20);
		position: relative;

		&:after {
			position: absolute;
			content: "";
			height: rem(22);
			width: 100%;
			left: 0;
			bottom: 0;
			background-color: $white;
		}
	}
	&-img {
		max-width: 30%;
		flex: 0 0 30%;
	}
	&-text {
		flex-grow: 1;
		padding: 1rem;
	}
	@include bdown(nm) {
		.item {
			display: block;
			margin-bottom: 2rem;
		}
		&-info {
			padding-bottom: 0;
			&:after {
				content: none;
			}
		}
		&-year {
			max-width: none;
			text-align: initial;
			span {
				width: auto;
			}
		}
	}
}
.intake-register {
	h6 {
		color: $violet;
		margin-bottom: 0;
	}
	a {
		@include transition;
		&:hover {
			filter: brightness(115%);
		}
	}
}
.forever-thankyou {
	&-link {
		display: flex;
		align-items: center;
		justify-content: center;
		@include transition;
		&:hover {
			filter: brightness(110%);
		}
		img {
			position: relative;
			z-index: 3;
			display: inline-block;
			@include bdown(md) {
				max-width: 40%;
			}
		}
		span {
			background-color: $violet;
			text-align: center;
			padding: 1.5rem 1.5rem 1.5rem 50px;
			color: $white;
			border-radius: rem(10);
			transform: translateX(-40px);
			font-size: 28px;
			font-weight: 700;
			max-width: 300px;
			min-height: rem(141);
			display: inline-flex;
			align-items: center;
			flex-grow: 1;
			@include bdown(hg) {
				font-size: 24px;
			}
			@include bdown(md) {
				font-size: 20px;
				min-height: 0;
			}
		}
	}
	&-row {
		@include bdown(lg) {
			display: block;
			.col {
				margin-bottom: 20px;
			}
		}
	}
}
.fading-img-link {
	&-container {
		.row {
			display: flex;
			flex-wrap: wrap;
			.col {
				margin-bottom: rem(30);
				display: flex;
				flex-direction: column;
				a {
					flex-grow: 1;
				}
			}
			@include bup(md) {
				.col {
					flex: 0 0 33.33%;
				}
			}
			@include bdown(lg) {
				.col {
					flex: 0 0 50%;
				}
			}
			@include bdown(md) {
				.col {
					flex: 0 0 100%;
				}
			}
		}
	}
	display: flex;
	position: relative;
	// height: 100%;
	color: $white;
	&:hover {
		.inner {
			color: $yellow;
			border-left-color: $yellow;
		}
	}
	&:after {
		content: "";
		display: block;
		position: absolute;
		z-index: -1;
		background-image: linear-gradient(
			to right,
			rgba(98, 76, 159, 1) 0,
			rgba(98, 76, 159, 1) 50%,
			rgba(98, 76, 159, 0) 60%
		);
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	img {
		width: 50%;
		height: inherit;
		object-fit: cover;
		position: relative;
		z-index: -2;
	}
	.inner {
		@include transition;
		padding: 1rem;
		border-left: rem(9) solid $green;
		width: 50%;
	}
	p {
		font-weight: 700;
		&:first-of-type {
			font-size: rem(14);
		}
		&:last-of-type {
			font-size: rem(22);
			margin-bottom: 0;
		}
	}
}
.also-visit {
	.masonry--inner {
		position: relative;
		@include transition;
		&:hover {
			filter: brightness(110%);
		}
	}
	img {
		width: 100%;
		// height: 100%;
		min-height: 75%;
		object-fit: cover;
	}
	span {
		display: block;
		padding: 1rem;
		position: absolute;
		top: 30%;
		left: 0;
		width: 100%;
		text-align: center;
		background-color: $violet;
		color: $white;
		@include font-count(14, 22);
	}
}
.more-programs {
	background-color: #f7f5f9;
	h3 {
		color: $violet;
	}
	.ww {
		color: $base-alt;
		font-size: rem(17);
	}
	.masonry > div {
		@include bdown(md) {
			padding: 5px 15px !important;
		}
	}
	.masonry--inner {
		padding: rem(8) 1rem;
		border: 3px solid $violet-light;
		border-radius: 1rem;
		@include transition;
		justify-content: center;
		display: flex;
		flex-direction: column;
		min-height: 4rem;
		@include bdown(md) {
			min-height: 5rem;
			border-width: 1px;
			padding: 5px 15px;
		}
		&:hover {
			background-color: $violet;
			.more-programs-text {
				color: $yellow;
			}
		}
	}
	&-category {
		color: $green;
		font-weight: 700;
		font-size: 14px;
		text-transform: uppercase;
	}
	&-text {
		color: $violet-light;
		font-weight: 700;
		font-size: rem(20);
		line-height: 1;
		min-height: 2rem;
		display: flex;
		align-items: center;
	}
}
.schools {
	background-color: $green;
	color: $white;
	h2 {
		color: $white;
	}
	.map-iframe-container {
		iframe {
			width: 100%;
			height: 450px;
		}
	}
}
.contact {
	&-questions {
		h6 {
			font-size: em(20);
			color: $violet;
		}
	}
	&-items {
		.masonry-inner {
			background-color: #f8faf4;
			border: none;
			padding: 2rem 2rem 0.5rem;
			position: relative;
			&:before {
				content: "\f3c5";
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				width: rem(44);
				height: rem(44);
				border-radius: 50%;
				background-color: $violet-light;
				font-family: $awesome;
				font-weight: 900;
				color: #f8faf4;
				left: -1rem;
			}
			h3 {
				color: $violet;
				font-size: em(33);
			}
		}
	}
	&-address,
	&-phone {
		font-size: rem(16);
		padding-left: 1rem;
		position: relative;
		&:before {
			font-family: $awesome;
			font-weight: 900;
			padding-right: 0.5rem;
			color: $yellow;
			position: absolute;
			display: block;
			left: 0;
			top: 0;
		}
	}
	&-address {
		&:before {
			content: "\f3c5";
		}
	}
	&-phone {
		&:before {
			content: "\f879";
		}
	}
	&-workhours {
		padding-top: 1rem;
		margin-top: 0.5rem;
		font-size: rem(16);
		border-top: 3px solid $base-alt;
		li {
			margin-bottom: 3px;
		}
		h6 {
			margin-bottom: 0;
			color: $violet;
		}
	}
	&-social {
		background-color: rgba($color: $violet-light, $alpha: 0.21);
		h4 {
			color: $violet;
		}
		h4 + p {
			color: $violet-light;
			font-family: $ak;
			font-size: rem(50);
		}
		ul.soc-share {
			display: flex;
			justify-content: center;
			a i {
				width: rem(36);
				height: rem(36);
				border-radius: 50%;
			}
		}
	}
	&-request {
		background-color: #f8faf4;
		.contact {
			&-person {
				color: $violet;
				font-size: rem(33);
				font-weight: 700;
				margin-bottom: 1rem;
				&-info {
					font-size: rem(20);
					font-weight: 700;
					color: $base-alt;
					a {
						&:hover {
							color: $violet;
						}
					}
				}
			}
		}
	}
	&-branch {
		.our-staff {
			background-color: transparent;
			.masonry-inner {
				a {
					padding-top: 0.5rem;
					position: relative;
					display: block;
					&:first-of-type {
						margin-top: 2rem;
						&:before {
							display: block;
							position: absolute;
							content: "";
							width: 30%;
							height: 3px;
							background-color: rgba(
								$color: $green,
								$alpha: 0.47
							);
							top: 0;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}
		}
	}
}
.staff-directory {
	&-link {
		display: block;
		text-align: center;
		&:hover {
			filter: brightness(115%);
		}
		span {
			&:first-child {
				font-family: $ak;
				font-size: rem(50);
				color: $violet;
				padding-right: 0.5rem;
				line-height: 1;
			}
			&:last-child {
				color: $base-alt;
				font-weight: 700;
				font-size: rem(22);
			}
		}
	}
}
.wtd {
	.row > .col {
		display: flex;
	}
	.inner {
		text-align: center;
		padding: 2rem 2rem 1rem;
		background-color: #f8faf4;
		height: inherit;
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
	&-text {
		margin-bottom: 1rem;
		span {
			&:first-child {
				color: $violet-light;
				font-family: $ak;
				font-size: rem(50);
				margin-right: 0.5rem;
				line-height: 0.8;
			}
			&:last-child {
				color: $base-alt;
				font-weight: 700;
				font-size: rem(22);
			}
		}
	}
}
.forever-friendship {
	background-color: #f4f2f7;
	font-size: rem(16);
	.masonry-inner {
		padding-top: 0;
		background-color: #f8faf4;
		position: relative;
		padding-bottom: 3rem;
		img {
			margin-left: -1rem;
			max-width: none;
			width: calc(100% + 2rem);
			margin-bottom: 1rem;
		}
		h5 {
			color: $violet;
			text-align: center;
		}
		p {
			font-size: rem(15);
		}
		.btn {
			background-color: $violet;
			color: $white;
			font-family: $karla;
			font-size: rem(13);
			position: absolute;
			bottom: 0;
			right: 0;
			border-bottom-right-radius: 0;
			padding: 0.75rem 1.5rem;
		}
	}
	.ff-btn {
		background-color: $green;
		color: $white;
		font-family: $karla;
		font-size: rem(40);
		padding: 1rem;
		display: inline-flex;
		align-items: center;
		border-radius: rem(16);
		font-weight: 700;
		&:hover {
			filter: brightness(110%);
		}
		img {
			margin-right: 0.5rem;
		}
	}
}
.donate-options {
	.masonry {
		& > div {
			&:nth-child(3n+2) {
				@include bup(md) {
					.masonry-inner {
						&:after {
							content: none;
						}
					}
				}
			}
		}
	}
	.masonry-inner {
		margin-bottom: 2rem;
		text-align: center;
		display: flex;
		flex-direction: column;
		p {
			flex-grow: 1; 
		}
		@include bup(md) {
			border: none;
			position: relative;
			// border-right: 3px solid rgba($color: #707070, $alpha: .1);
			box-shadow: none;
			.img-container {
				min-height: 130px;
			}
			&:after {
				position: absolute;
				display: block;
				content: "";
				right: -16px;
				top: 0;
				width: 3px;
				height: 100%;
				background-color: rgba($color: #707070, $alpha: 0.1);
			}
		}
		h5 {
			color: $violet;
		}
	}
}
.donation {
	&-inside {
		h3 {
			font-family: $ak;
			color: $violet-light;
			font-size: rem(50);
		}
	}
	&-story {
		background-color: #f4f2f7;
		&-main {
			@include bup(md) {
				.col {
					max-width: 50%;
				}
			}
			.quote {
				&-top {
					font-size: rem(30);
					font-weight: 700;
					color: $violet;
					position: relative;
					&:before {
						content: "";
						position: absolute;
						display: block;
						width: rem(158);
						height: rem(158);
						background-image: url(../../images/icons/quotes.svg);
						top: -100%;
						left: 0;
						background-size: contain;
					}
				}
				&-bottom {
					color: $green;
					font-family: $ak;
					font-size: rem(32);
					text-align: right;
				}
				&-signature {
					color: $green;
					font-family: $ak;
					font-size: rem(32);
				}
			}
		}
	}
	&-image-container {
		img {
			box-shadow: 0 4px 12px rgba($color: #000000, $alpha: 0.25);
		}
	}
}
.campaign-progress {
	&-top {
		height: 0;
		padding-top: 26%;
		width: 100%;
		background-size: cover;
	}
	&-bottom {
		color: $white;
		background-color: #c9d0c2;
		h3 {
			color: inherit;
		}
	}
}
.cpb {
	&-line {
		display: flex;
		justify-content: space-between;
		&-graph {
			height: rem(100);
			width: 100%;
			background-color: $white;
			position: relative;
		}
		&-raised {
			position: absolute;
			height: 100%;
			left: 0;
			background-color: $violet-light;
			width: 30%;
			.number-raised {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				font-family: $karla;
				font-weight: 700;
				font-size: rem(40);
			}
		}
	}
	&-more {
		text-align: center;
		span {
			display: block;
			font-weight: 700;
			line-height: 1;
			&:first-of-type {
				@include font-count(30, 50);
				// font-size: rem(50);
			}
			&:last-of-type {
				// font-size: rem(88);
				@include font-count(40, 88);
				color: $violet-light;
			}
		}
	}
}
.benefactors {
	&-main {
		h4 {
			color: $violet;
		}
		p {
			font-weight: 700;
		}
	}
	&-all {
		.masonry {
			& > div {
				padding: 8px !important;
			}
		}
		&-1,
		&-2,
		&-3 {
			.masonry-inner {
				display: flex;
				color: $white;
				align-items: center;
				.benefactor-img {
					@include bdown(md) {
						max-width: 30%;
					}
				}
				.info {
					margin: 0 2% 0 5%;
				}
				h5,
				h6 {
					color: inherit;
				}
				h5 + h6 {
					margin-top: -1rem;
				}
				h6 {
					font-size: rem(22);
				}
				.relation {
					font-family: $ak;
					font-size: rem(29);
				}
			}
		}
		&-1 {
			.masonry-inner {
				background-color: $violet;
				border: none;
				.benefactor-img {
					max-width: 183px;
				}
				h5 {
					font-size: rem(46);
				}
			}
		}
		&-2 {
			.masonry-inner {
				background-color: $violet-light;
				border: none;
				.benefactor-img {
					max-width: 183px;
				}
				h5 {
					font-size: rem(30);
				}
			}
		}
		&-3 {
			.masonry-inner {
				background-color: #c6cdbf;
				color: #404040;
				.benefactor-img {
					max-width: 104px;
				}
				h5 {
					font-size: rem(26);
					font-family: $karla;
					margin-bottom: 0.8rem;
				}
				.relation {
					color: $white;
				}
			}
		}
		&-4 {
			.masonry-inner {
				background-color: $green;
				color: $white;
			}
		}
		&-5 {
			.masonry-inner {
				background-color: #c6cdbf;
				color: #404040;
			}
		}
		&-3,
		&-4,
		&-5 {
			.masonry-inner {
				border: none;
			}
		}
		&-4,
		&-5 {
			.masonry-inner {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}
.lifetime-heading {
	h2 {
		font-size: rem(50);
		color: $base;
		font-weight: normal;
	}
}
.board-members {
	display: flex;
	.board {
		&-left {
			flex: 1 0 70%;
			.masonry-inner {
				background-color: $violet;
				color: $white;
				padding: 2rem 1rem;
				.name {
					font-size: rem(25);
					font-weight: 700;
				}
				.position {
					font-size: rem(17);
				}
			}
		}
		&-right {
			flex: 1 0 auto;
			.inner {
				background-color: $violet;
			}
		}
	}
	&-list {
		background-color: $violet;
		color: $white;
		min-height: calc(100% - 30px);
		padding: 3rem 2rem;
		font-size: rem(25);
		font-weight: 700;
		margin-top: 15px;
		li {
			margin-bottom: 0.5rem;
		}
	}
	@include bup(md) {
		&-list {
			margin-left: 30px;
		}
	}
	@include bdown(md) {
		display: block;
	}
}
.board-media,
.staff-media {
	&-inquiries {
		a {
			color: $green;
			&:hover {
				color: $violet;
			}
		}
		span {
			display: block;
			&:nth-child(1) {
				color: $violet;
				font-size: rem(23);
			}
		}
	}
	&-banner {
		a {
			background-color: $green;
			color: $white;
			display: inline-flex;
			align-items: center;
			&:hover {
				filter: brightness(115%);
			}
		}
		img {
			display: block;
		}
		span {
			font-size: rem(33);
			font-weight: 700;
			display: block;
			padding: 0 3rem;
			line-height: 1;
		}
		@include bdown(md) {
			a {
				display: block;
			}
			img {
				width: 100%;
			}
			span {
				padding: 2rem;
				text-align: center;
			}
		}
	}
}
.staff-media {
	.board-media-banner {
		a {
			background-color: $violet-light;
			&:hover {
				background-color: $green;
			}
		}
	}
}
.single-program {
	&-age {
		font-size: rem(50);
		color: $violet-light;
		font-family: $ak;
		line-height: 1;
	}
	&-info {
		position: relative;
		font-weight: 700;
		color: $base-alt;
		font-size: rem(22);
		&-top {
			display: flex;
			justify-content: space-between;
			column-gap: 1rem;
			margin-bottom: 2rem;
			& > li {
				position: relative;
				padding-left: 2rem;
				& > i {
					position: absolute;
					color: $yellow;
					left: 0;
					transform: translateY(25%);
				}
			}
			@include bdown(md) {
				display: block;
				margin-bottom: 0;
			}
		}
		& > li {
			position: relative;
			padding-left: 2rem;
			& > i {
				position: absolute;
				color: $yellow;
				left: 0;
				transform: translateY(25%);
			}
		}
		&-calendar {
			margin-top: 0.5rem;
			@include bdown(md) {
				margin-top: 0;
			}
		}
	}
	&-bottom-button {
		display: block;
		color: $white;
		background-color: $green;
		text-align: center;
		// margin-top: 1rem;
		// font-size: rem(20);
		// font-size: .8rem;
		margin-top: 0.5rem;
		padding: 0.6rem 1rem;
		border-radius: 1rem;
		font-weight: 700;
		@include bup(md) {
			margin-top: 1rem;
			display: inline-block;
		}
		@include bdown(md) {
			display: block;
		}
		&:hover {
			background-color: $violet;
		}
	}
	&-pre {
		font-size: rem(21);
		font-weight: 700;
	}
	&-details {
		color: $base-alt;
		font-size: rem(17);
		&-button {
			// font-size: rem(25);
			font-family: $karla;
			color: $white;
			display: inline-flex;
			border-radius: 1rem;
			padding: 0.6rem 1rem;
			align-items: center;
			justify-content: space-evenly;
			border-top: 2px solid transparent;
			flex: 0 0 30%;
			@include bup(md) {
				max-width: 260px;
			}
			span {
				display: block;
				line-height: 1;
				&:first-child {
					font-size: 14px;
					font-weight: 700;
				}
				&:last-child {
					font-size: 18px;
					font-weight: 700;
				}
			}
			img {
				margin-right: 1rem;
				height: rem(35);
			}
			&.spdb-stin {
				background-color: $violet;
				border-color: $violet;
			}
			&.spdb-login {
				background-color: $green;
				border-color: $green;
			}
			&.spdb-volunteer {
				background-color: $yellow;
				border-color: $yellow;
				margin-bottom: 0;
			}
			&:hover {
				filter: brightness(90%);
			}
			@include bdown(nm) {
				display: flex;
				align-items: center;
				margin-bottom: 0.5rem;
				.text-center {
					flex: 1 1 auto;
				}
			}
		}
		&-buttons {
			@include bup(nm) {
				display: flex;
				justify-content: space-between;
			}
		}
	}
}
.facilities {
	background-color: #f4f2f7;
	font-size: rem(16);
	.masonry-inner {
		padding-top: 0;
		background-color: #eaeee8;
		position: relative;
		padding-bottom: 3rem;
		border: none;
		.img-container {
			margin-left: -1rem;
			margin-bottom: 1rem;
			max-width: none;
			width: calc(100% + 2rem);
			position: relative;
			&:after {
				content: "";
				position: absolute;
				display: block;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 30%;
				background-image: linear-gradient(
					to top,
					rgba(0, 0, 0, 0.84),
					rgba(0, 0, 0, 0)
				);
			}
		}
		img {
			width: 100%;
			display: block;
		}
		h5 {
			color: $violet;
			text-align: center;
		}
		p {
			font-size: rem(15);
		}
	}
}
.our-staff {
	background-color: #f4f2f7;
	font-size: rem(16);
	.masonry-inner {
		padding-top: 0;
		background-color: #eaeee8;
		position: relative;
		padding-bottom: 3rem;
		border: none;
		text-align: center;
		.img-container {
			max-width: none;
			position: relative;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			transform: translateY(-50%);
			img {
				display: inline-block;
				max-width: 77px;
				margin-left: 2px;
				margin-right: 2px;
			}
		}
		h5 {
			color: $violet;
			text-align: center;
		}
		p {
			font-size: rem(15);
		}
		a {
			color: $green;
			font-size: rem(16);
			font-weight: 700;
			&:hover {
				color: $yellow;
			}
		}
	}
}
.branch {
	&-info {
		background-color: $green;
		color: $white;
		text-align: center;
		z-index: 15;
		padding-top: 0;
		padding-bottom: 0;
		&.alt {
			background-color: $violet;
		}
		a {
			color: $yellow;
			&:hover {
				color: $white;
			}
		}
		@include bdown(md) {
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
		&-list {
			display: flex;
			justify-content: center;
			.smooth-scroll {
				&.current-under.active {
					color: $yellow;
					position: relative;
					&:after {
						position: absolute;
						display: block;
						content: "\f061";
						top: 50%;
						transform: translateY(-50%);
						right: 5px;
						font-family: $awesome;
						font-weight: 900;
					}
				}
			}
			&.five-items {
				li {
					flex: 0 0 20%;
				}
			}
			h5 {
				color: inherit;
				margin-bottom: 0;
			}
			&:not(.alt) {
				a {
					padding: 1rem 2rem;
					color: $white;
					display: block;
					@include bup(md) {
						// height: 100%;
						display: flex;
						flex-grow: 1;
						align-items: center;
						justify-content: center;
					}
					background-color: rgba($color: #000000, $alpha: 0);
					position: relative;
					&:after {
						content: "\f061";
						display: block;
						position: absolute;
						font-family: $awesome;
						font-weight: 900;
						top: 50%;
						right: 5px;
						transform: translateY(-50%);
						opacity: 0;
						transition: opacity 0.3s ease-in-out;
					}
					&:hover {
						color: $yellow;
						background-color: rgba($color: #000000, $alpha: 0.1);
						&:after {
							opacity: 1;
						}
					}
				}
			}
			&.alt {
				li {
					padding: 1rem 2rem;
					&:nth-of-type(3) {
						p:last-child {
							a:after {
								content: "\f061";
								font-family: $awesome;
								padding-left: 5px;
								display: inline-block;
							}
						}
					}
				}
				a {
					color: $yellow;
					&:hover {
						color: $white;
					}
				}
			}
			li {
				border-left: 3px solid #ebeee9;
				font-size: rem(16);
				display: inline-flex;
				flex-direction: column;
				justify-content: center;

				&:last-of-type {
					border-right: 3px solid #ebeee9;
				}
				p {
					margin-bottom: 0;
					a {
						white-space: nowrap;
						&:hover {
							color: $yellow;
						}
						&.book-party {
							font-weight: 700;
							font-size: rem(17);
							i {
								margin-left: 1rem;
							}
						}
					}
				}
			}

			@include bup(md) {
				li {
					max-width: 230px;
				}
				&.alt {
					li {
						max-width: none;
					}
				}
			}
			@include bdown(md) {
				display: block;
				li {
					border: none !important;
					margin-bottom: 0.5rem;
					display: block;
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
.follow-branch {
	h3 {
		color: $white;
		margin-bottom: 0;
		font-weight: normal;
		text-align: center;
		@include bup(md) {
			text-align: right;
		}
		span {
			&:first-child {
				font-family: $ak;
				font-size: rem(94);
			}
			&:last-child {
				font-family: $ak;
				font-size: rem(45);
			}
		}
	}
	.soc-share {
		a i {
			border-radius: 50%;
			font-size: 2rem;
			height: rem(64);
			width: rem(64);
		}
		@include bdown(md) {
			display: flex;
			justify-content: center;
		}
	}
}
.store-locator {
	background-color: $green;
	color: $white;
	font-family: $karla;
	font-size: rem(26);
	h3 {
		color: $violet;
	}
}
.wedding-reception,
.umatter-banner {
	color: $white;
	background-color: $green;
	.ww {
		font-family: $karla;
		font-size: rem(26);
	}
	&-from {
		font-family: $ak;
		font-size: rem(32);
	}
}
.weekly-thought {
	color: $white;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
	}
}
.previous-exhibitions {
	&-title {
		color: $violet;
		font-size: rem(24);
		font-weight: 700;
	}
	&-example {
		span {
			display: block;
			font-size: rem(21);
			font-weight: 700;
		}
		a {
			&:hover {
				filter: brightness(110%);
			}
		}
	}
	.btn-container {
		display: flex;
		align-self: center;
	}
}
.soul-studio-artist {
	background-size: cover;
	background-repeat: no-repeat;
	color: $white;
	position: relative;
	&:before {
		content: "";
		display: block;
		background-color: rgba($color: #000000, $alpha: 0.84);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: inherit;
	}
	.container {
		padding-left: 5%;
		padding-left: 5%;
		position: relative;
		z-index: 3;
	}
	.ssa-list {
		display: flex;
		padding-top: 5rem;
		padding-bottom: 5rem;
		li {
			&:nth-child(1) {
				img {
					transform: scale(1.2);
					transform-origin: left center;
				}
			}
			&:nth-child(2) {
				position: relative;
				z-index: 3;
				img {
					transform: translate(-15%, 5%) rotate(-5deg) scale(1.2);
				}
			}
			&:nth-child(3) {
				img {
					transform: scale(1.2);
				}
			}
			&:nth-child(4) {
				position: relative;
				z-index: 3;
				img {
					transform: translate(-15%, 10%) scale(1.2);
				}
			}
			&:nth-child(5) {
				img {
					transform: scale(1.2);
					transform-origin: right center;
				}
			}
			img {
				transform-origin: center;
			}
		}
	}
}
.grateful-to-organizations {
	.gto-list {
		display: flex;
		li {
			padding: 1rem;
		}
	}
	.gto-container {
		.row {
			align-items: center;
		}
		p {
			margin-bottom: 0;
			color: $violet;
			font-weight: 700;
			font-size: rem(24);
		}
	}
}
.exhibition {
	&-current {
		background-color: rgba($color: $green, $alpha: 0.21);
	}
	&-slider-container {
		@include bup(md) {
			max-width: 50%;
		}
	}
	&-current-title {
		color: $white;
		font-size: rem(47);
		position: relative;
		padding-top: 5px;
		padding-bottom: 5px;
		@include bup(md) {
			max-width: calc(50% - 15px);
		}
		&:after {
			content: "";
			display: block;
			position: absolute;
			background-color: $violet;
			right: 0;
			width: 9999px;
			top: 0;
			height: 100%;
			z-index: -1;
		}
	}
	&-info {
		h2 {
			margin-bottom: 0;
		}
		h6 {
			color: $violet;
			margin-bottom: 0;
			& + p {
				font-weight: 700;
				color: $base-alt;
			}
		}
		.ww {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: $violet;
			}
		}
	}
	&-past {
		background-color: rgba($color: $violet-light, $alpha: 0.16);
		padding-top: 2rem;
		padding-bottom: 2rem;
		margin-bottom: 1rem;
	}
}
.recovery-meetings {
	&-list {
		display: grid;
		grid-template-columns: 48% 48%;
		grid-auto-rows: 1fr;
		li {
			margin-bottom: 1rem;
			padding-left: 5rem;
			.inner {
				background-color: $green;
				border-right: rem(40) solid $violet;
				color: $white;
				height: 100%;
				padding: 1rem 1rem 1rem 3rem;
				position: relative;
				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					color: inherit;
				}
				.date {
					position: absolute;
					left: 0;
					top: 50%;
					transform: translate(-50%, -50%);
					background-color: $violet;
					border: 6px solid $green;
					border-radius: 50%;
					height: rem(100);
					width: rem(100);
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					span {
						display: block;
						font-weight: 700;
						line-height: 1;
						&:first-child {
							font-size: rem(27);
						}
					}
				}
			}
		}
		@include bdown(md) {
			grid-template-columns: 100%;
			li {
				padding-left: 3rem;
			}
		}
	}
}
.friendship-house-violet-banner {
	background-color: $violet;
	color: $white;
	font-size: rem(27);
	color: $white;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: inherit;
	}
}

// Safari only
@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) {
		.masonry {
			& > div {
				display: flex;
				flex-direction: column;
				height: inherit;
			}
			&-inner,
			&--inner {
				height: auto !important;
				flex-grow: 1;
			}
		}
		.showcase-gallery {
			img {
				min-height: 0;
			}
			&.layout-2 {
				.top-img {
					img {
						height: auto;
					}
				}
				.aside-img-1,
				.aside-img-2 {
					img {
						height: 300px;
					}
				}
			}
		}
		.fading-img-link {
			img {
				height: 160px;
			}
		}
		@include bup(md) {
			.programs-slider {
				height: auto;
			}
		}
	}
}
.shabbatbag-buttons {
	& > div {
		cursor: pointer;
		@include bdown(sm) {
			margin-bottom: 1rem;
			display: block;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.team--name-block {
	.name {
		h3 {
			color: $violet;
		}
	}
}
.founding-supporters-list {
	.masonry-inner {
		border: none;
		box-shadow: none;
		padding: 0 0 5px 0;
		height: auto;
	}
}
iframe {
	&.online-donation-iframe {
		height: 1450px;
		@include bdown(sm) {
			height: 1660px;
		}
	}
	&.tribute-donation-iframe {
		height: 2100px;
		@include bdown(sm) {
			height: 2450px;
		}
	}
}
#w4f-lb {
	display: none;
	border: 2px solid $violet;
	border-radius: 1rem;
	a {
		outline: none;
	}
}
.fancybox-button[disabled]{
	display: none;
}

.footer {
	border-top: rem(25) solid $green;
	&.no-border {
		border: none;
	}
	&-top {
		background-color: #333;
		padding-top: 2rem;
		padding-bottom: 2rem;
		color: $white;
		a {
			@include transition;
			&:hover {
				color: $yellow;
			}
		}
		.photo-gallery {
			font-family: $ak;
			color: $yellow;
			font-size: rem(50);
		}
	}
	&-contacts {
		h6 {
			color: $white;
			font-size: rem(20);
			padding-bottom: 0.5rem;
			border-bottom: 2px solid #434343;
			margin-bottom: 0.5rem;
			font-family: $karla;
		}
		li {
			margin-bottom: 1rem;
			font-family: $krub;
			font-size: rem(14);
			color: #c9c9c9;
		}
	}
	&-address {
		&:before {
			content: "\f3c5";
			font-family: $awesome;
			color: $yellow;
			font-weight: 900;
			padding-right: 0.5rem;
		}
	}
	&-phone {
		&:hover {
			color: $yellow;
		}
		&:before {
			content: "\f879";
			font-family: $awesome;
			color: $yellow;
			font-weight: 900;
			padding-right: 0.5rem;
		}
	}
	&-bottom {
		background-color: $violet;
		color: $white;
		font-family: $krub;
		font-size: rem(16);
		font-weight: 500;
		padding-top: 2rem;
		padding-bottom: 2rem;
		position: relative;
		.bottom-links {
			margin-bottom: 0.5rem;
			li {
				display: inline-block;
				a {
					padding: 0 0.5rem;
				}
				&:last-of-type {
					&:after {
						content: none;
					}
				}
				&:after {
					content: "|";
					display: inline-block;
				}
			}
			&-container {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
			&-group {
				padding-right: 2%;
				text-align: right;
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				align-items: flex-end;
				.made-by {
					color: $violet-light;
					font-size: 9px;
					padding-right: 0.5rem;
					padding-left: 0.5rem;
					letter-spacing: 1.25;
					&:hover {
						color: $white;
					}
				}
				@include bdown(md) {
					align-items: flex-start;
				}
			}
		}
		.to-top {
			background-color: $yellow;
			color: $white;
			width: rem(72);
			height: rem(72);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: rem(32);
			border-radius: rem(10);
			@include transition;
			&:hover {
				color: $violet;
			}
		}
		.copyright-container {
			display: flex;
			align-items: center;
		}
	}
	.quick-links {
		@include bdown(md) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
		}
		@include bdown(xs) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}
		li {
			margin-bottom: 1rem;
			a {
				padding-left: 1rem;
				position: relative;
				display: inline-block;
				&:before {
					content: "\f105";
					left: 0;
					position: absolute;
					padding-right: 0.5rem;
					font-family: $awesome;
					font-weight: 900;
				}
			}
		}
		&--container {
			font-size: rem(14);
			padding-top: 1.5rem;
			h6 {
				font-size: rem(18);
			}
		}
	}
	.subscribe-block {
		h4 {
			font-size: rem(31);
			color: $violet;
			& + p {
				color: #7d8589;
				max-width: 60%;
				font-size: rem(16);
				@include bdown(md) {
					max-width: none;
				}
			}
		}
	}
	@include bdown(md) {
		&-top {
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
		&-bottom {
			padding-top: 1rem;
			padding-bottom: 1rem;
			.bottom-links {
				&-container {
					justify-content: flex-start;
				}
			}
			.to-top {
				position: absolute;
				top: 1rem;
				right: 5%;
			}
		}
	}
	&-logo {
		img {
			@include bdown(md) {
				max-width: 40%;
			}
		}
	}
}

$black: #000000;
$white: #FFF;
$base: #404040;
$base-alt: #707070;
$gray: #D4D4D4;
$beige: #F7F7F7;
$violet: #624C9F;
$violet-light: #A195BF;
$yellow: #F2B84B;
$green: #9AAC90;
$olive: #F8FAF4;

$box: 1340px;
$bfs: 19;
$awesome: 'Font Awesome 5 Free';
$awesomeb: 'Font Awesome 5 Brands';
$karla: 'Karla', sans-serif;
$krub: 'Krub', sans-serif;
$sen: 'Sen', sans-serif;
$ak: 'a Amenity Kits';

@font-face {
    font-family: $ak;
    src: url('../fonts/aamenitykits.eot');
    src: url('../fonts/aamenitykits.eot?#iefix') format('embedded-opentype'),
        url('../fonts/aamenitykits.woff2') format('woff2'),
        url('../fonts/aamenitykits.woff') format('woff'),
        url('../fonts/aamenitykits.ttf') format('truetype'),
        url('../fonts/aamenitykits.svg#aAmenityKits') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

//breakpoints
$breakpoints: (
    'hd': 1920px,
    'shd': 1700px,
    'hg': 1600px,
    'lg': 1430px,
    'nm': 1250px,
    'md': 991px,
    'sm': 768px,
    'xs': 480px,
    'xxs': 385px
)!default;

.single-resource {
    .featured-image {
        img {
            width: 100%;
        }
    }
    p {
        text-align: justify;
    }
    .alignright,
    .alignleft {
        max-width: 150px;
        ::after {
            content: "";
            clear: both;
            display: block;
        }
    }
    .alignleft {
        float: left;
        margin: 0.5em 1em 0.5em 0;
    }
    .alignright {
        float: right;
        margin: 0.5em 0 0.5em 1em;
    }
    .row {
        .right-sidebar {
            a {
                img {
                    margin-bottom: 2rem;
                }
            }
        }
    }
    .author-area {
        .author-image {
            text-align: center;
        }
    }
    @include bdown(md) {
        .author-top-image {
            display: none;
        }
    }

    @include bdown(sm) {
        .row {
            display: block;
            .right-sidebar {
                display: flex;
                justify-content: space-evenly;
                a {
                    flex-basis: 35%;
                    img {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .author-area {
            margin-top: 0;
            padding-top: 0;
        }
    }
}

html {
    font-size: #{$bfs}px;
    @include bdown(md){
        font-size: 14px;
    }
}
body {
    color: $base;
    line-height: 1.5;
    position: relative;
    font-family: $sen;
}
img {
    &.img-fw {
        width: 100%;
    }
}
a {
    @include transition;
    obj {
        pointer-events: none;
    }
}
.serif {
    font-family: serif;
}
.sans-serif {
    font-family: sans-serif;
}
.btn {
    padding: 1rem 1.5rem;
    text-align: center;
    text-decoration: none;
    border-radius: rem(20);
    font-weight: 400;
    @include fluid-font(14px);
    @include letter-spacing(100);
    background-color: transparent;
    display: inline-block;
    color: $white;
    font-family: $karla;
    font-weight: 700;
    @include transition;
    &:hover {
        filter: brightness(110%);
    }
    &.disabled {
        cursor: not-allowed;
    }
    &-violet {
        background-color: $violet;
    }
    &-red {
        background-color: red;
    }
    &-violet-light {
        background-color: $violet-light;
    }
    &-green {
        background-color: $green;
    }
    &-yellow {
        background-color: $yellow;
    }
    @include bup(md){
        min-width: 202px;
    }
    &-inverted {
        background-color: $black;
        color: $white;
    }
    &-small {
        @include fluid-font(12px);
        @include bup(md){
            min-width: 125px;
        }
    }
    &-large {
        @include fluid-font(20px);
        @include bup(md){
            min-width: 220px;
        }
    }
    &-rounded {
        border-radius: 1rem;
    }
    &.d-block {
        display: block;
    }
}
ul {
    &.yellow-dots {
        li {
            list-style-type: disc;
            &::marker {
                color: $yellow;
            }
        }
    }
}
.table-container {
    overflow-x: auto;
}
.table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: inherit;
    width: 100%;
    td, th {
        vertical-align: top;
        border: 1px solid $gray;
        padding: 5px;
    }
    th {
        font-weight: 700;
        text-align: center;
    }
    &.text {
        &-left {
            td, th {
                text-align: left;
            }
        }
        &-right {
            td, th {
                text-align: right;
            }
        }
        &-center {
            td, th {
                text-align: center;
            }
        }
    }
    &.table-inverted{
        color: $beige;
    }
    &.table-valign {
        &-top {
            td, th {
                vertical-align: top;
            }
        }
        &-middle {
            td, th {
                vertical-align: middle;
            }
        }
        &-bottom {
            td, th {
                vertical-align: bottom;
            }
        }
    }
}
p {
    margin-bottom: 1rem;
}
h1, h2, h3, h4, h5, h6 {
    color: $green;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 1rem;
    @include letter-spacing(-20);
    &.alt {
        span {
            display: block;
        }
    }
    span + span {
        font-family: $ak;
        padding-left: 1rem;
        position: relative;
        color: $violet-light;
        font-weight: 400;
    }
}
.alt-title {
    h1, h2, h3, h4, h5, h6 {
        color: $base-alt;
        font-weight: normal;
    }
}
h1 {
    font-size: em(60);
}
h2 {
    font-size: em(50);
}
h3 {
    font-size: em(47);
}
h4 {
    font-size: em(34);
}
h5 {
    font-size: em(26);
}
h6 {
    font-size: em(24);
}
xmp {
    font-size: .7rem;
    font-family: monospace;
}
.code-container {
    overflow-x: auto;
    background-color: $gray;
}
.text {
    &-white {
        color: $white;
    }
    &-beige {
        color: $beige;
    }
    &-gray {
        color: $gray;
    }
    &-black {
        color: $black;
    }
}
.bg {
    &-white {
        background-color: $white;
    }
    &-beige {
        background-color: $beige;
    }
    &-gray {
        background-color: $gray;
    }
    &-black {
        background-color: $black;
    }
    &-yellow {
        background-color: $yellow;
    }
    &-violet {
        background-color: $violet;
    }
    &-violet-light {
        background-color: $violet-light;
    }
    &-olive {
        background-color: $olive;
    }
}
.text {
    &-white {
        color: $white;
    }
    &-base {
        color: $base;
    }
    &-base-alt {
        color: $base-alt;
    }
    &-beige {
        color: $beige;
    }
    &-gray {
        color: $gray;
    }
    &-black {
        color: $black;
    }
    &-yellow {
        color: $yellow;
    }
    &-violet {
        color: $violet;
    }
}
@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
.ww {
    a:not(.btn) {
        text-decoration: underline;
        color: $violet;
        &:hover {
            opacity: .9;
        }
    }
    ul, ol {
        margin-left: 1rem;
        margin-bottom: 1rem;
    }
    ul {
        li {
            list-style-type: disc;
            &::marker {
                color: $yellow;
            }
        }
    }
    &.alt-title-color {
        h1, h2, h3, h4, h5, h6 {
            color: $violet;
        }
    }
    &.ww-violet {
        a:not(.btn) {
            text-decoration: underline;
            color: $white;
            &:hover {
                opacity: .9;
            }
        }
    }
}
.fg-0 {
    flex-grow: 0;
}
.fg-1 {
    flex-grow: 1;
}
.fg-2 {
    flex-grow: 2;
}
.back-link {
    font-size: 12px;
    color: $base-alt;
    @include transition;
    &:hover {
        color: $yellow;
    }
    i {
        color: $yellow;
        padding-right: .5rem;
    }
    &.reversed {
        i {
            padding-left: .5rem;
            padding-right: 0;
        }
    }
}
.forward-link {
    font-size: 15px;
    color: $green;
    font-family: $karla;
    text-decoration: none;
    font-weight: 700;
    @include transition;
    &.alt {
        color: $violet;
    }
    i {
        padding-left: .5rem;
    }
}
.video-top {
    .media-wrapper {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}
.iframe-container {
    position: relative;
    height: 0;
    padding-top: 56.25%;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}



@mixin bdown($bp, $bps:$breakpoints) {
    $var: map-get($bps, $bp) - 1px;
    @media screen and (max-width: $var) {
        @content;
    }
}
@mixin bup($bp, $bps:$breakpoints) {
    @media screen and (min-width: map-get($bps, $bp)) {
        @content;
    }
}

@mixin bonly($bp, $bps:$breakpoints) {
    $lst-bps: map-keys($bps);
    $idx: index($lst-bps, $bp);
    $var: map-get($bps, $bp) - 1px;
    @if( ($idx > 1) and ($idx < length($lst-bps)) ){
        @media screen and (min-width: map-get($bps, nth($lst-bps, ($idx + 1)))) and (max-width: $var) {
            @content;
        }
    }
    @else {
        @if ($idx == 1) {
            @media screen and (min-width: map-get($bps, $bp)) {
                @content;
            }
        }
        @else {
            @media screen and (max-width: $var) {
                @content;
            }
        }
    }
}

@mixin bbetween($bp1, $bp2, $bps:$breakpoints) {
    $lst-bps: map-keys($bps);
    $idx: index($lst-bps, $bp1);
    $start: map-get($bps, $bp1);
    $end: map-get($bps, $bp2) - 1px;
    @if ($end < $start) {
        $start: $start + $end;
        $end: $start - $end;
        $start: $start - $end;
    }
    @media screen and (min-width: $start) and (max-width: $end) {
        @content;
    }
}
// @mixin clear-link {
//     &:link,
//     &:visited ,
//     &:hover ,
//     &:active {
//         text-decoration: none;
//     }
// }
@mixin transition {
    transition: all .3s ease-in-out;
}
@mixin resp-media($name, $color, $color-hover) {
    .div-#{$name} {
        a {
            color: #{$color};
            &:focus, &:hover {
                color: #{$color-hover};
            }
        }
    }
}
@mixin animations($value) {
    -webkit-animation: $value;
    -moz-animation: $value;
    -o-animation: $value;
    animation: $value;
}
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}
@mixin block-center {
    margin-left: auto;
    margin-right: auto;
}
@mixin bcw ($value) {
    margin-left: auto;
    margin-right: auto;
    max-width: $value;
}
@mixin rlh ($value){
    @include bup(md){
        line-height: $value;
    }
    @include bdown(md){
        line-height: inherit;
    }
}
@mixin font-count($mins, $maxs){
    $minspx: #{$mins + px};
    $dif: #{($maxs - $mins) * .05vw};
    font-size: calc(#{$minspx} + #{$dif});
    @include bup(hd) {
        font-size: #{$maxs + px};
    }
    @include bdown(xs) {
        font-size: #{$mins + px};
    }
}
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}
@mixin fluid-font($max-font-size) {
    $min-font-size: $max-font-size/1.4;
    $min-vw: 480px;
    $max-vw: 1920px;
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    font-size: $min-font-size;
    @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
    }
    @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
    }
}

@mixin letter-spacing($points) {
    $empoints: #{$points / 1000};
    letter-spacing: #{$empoints + em};
}

@mixin btn-detail($color, $hover-color, $border-width, $fsmin, $fsmax) {
    color: #{$color};
    border: #{$border-width}px solid $color;
    @include font-count($fsmin, $fsmax);
    @include transition;
    &:hover {
        color: #{$hover-color};
        border: #{$border-width}px solid $hover-color;
    }
}
@mixin row() {
	display: flex;
	flex-wrap: wrap;
}

@mixin column($width, $grow:0, $shr: 1, $bs: $width) {
	width: $width;
	flex: #{$grow} #{$shr} #{$bs};
}

@mixin liquid-column($cols, $col-gap){
    column-count: $cols;
    @if ($col-gap){
        column-gap: $col-gap;
    }
}

@for $i from 1 through 20 {
    $iteration: 5;
    $j: $i * $iteration;
    $flex-basis: $j;
    .sized-#{$j} {
        @include bup(md){
            max-width: percentage($flex-basis)/100;
        }
    }
    .sized-mobile-h-#{$j} {
        @include bbetween(xs, md){
            max-width: percentage($flex-basis)/100;
        }
    }
    .sized-mobile-v-#{$j} {
        @include bdown(xs) {
            max-width: percentage($flex-basis)/100;
        }
    }
    .basis-#{$j} { 
        flex-basis: percentage($flex-basis)/100 !important;
        @include bup(md){
            max-width: percentage($flex-basis)/100;
        }
    }
    .basis-mobile-h-#{$j} {
        @include bbetween(xs, md){
            flex-basis: percentage($flex-basis)/100 !important;
        }
    }
    .basis-mobile-v-#{$j} {
        @include bdown(xs) {
            flex-basis: percentage($flex-basis)/100 !important;
        }
    }
}
@for $i from 2 through 6 {
    .wrap-#{$i}{
        flex-wrap: wrap;
        display: flex;
        margin-left: -15px;
        margin-right: -15px;
        .col {
            flex: 0 0 calc(100% / #{$i});
        }
        & > * {
            flex: 0 0 calc(100% / #{$i});
            padding: 15px;
        }
    }
    @include bbetween(xs, md){
        .wrap-#{$i}{
            .col {
                flex: 0 0 49%;
            }
            & > * {
                flex: 0 0 49%;
            }
        }
    }
    @include bdown(xs){
        .wrap-#{$i}{
            .col {
                flex: 0 0 100%;
            }
            & > * {
                flex: 0 0 100%;
            }
        }
    }
}
@for $i from 1 through 6 {
    @include bbetween(xs, md){
        .wrap-mobile-h-#{$i}{
            .col {
                flex: 0 0 calc(100% / #{$i});
            }
            & > * {
                flex: 0 0 calc(100% / #{$i});
                padding: 15px;
            }
        }
    }
    @include bdown(xs){
         .wrap-mobile-v-#{$i}{
            .col {
                flex: 0 0 calc(100% / #{$i});
            }
            & > * {
                flex: 0 0 calc(100% / #{$i});
                padding: 15px;
            }
        }
    }
}
@for $i from 2 through 6 {
    .column-count-#{$i}{
        column-count: #{$i};
    }
}
@for $i from 0 through 10 {
    .m-#{$i}{
        margin: #{$i}rem;
    }
    .mt-#{$i}{
        margin-top: #{$i}rem;
    }
    .mb-#{$i}{
        margin-bottom: #{$i}rem;
    }
    .ml-#{$i}{
        margin-left: #{$i}rem;
    }
    .mr-#{$i}{
        margin-right: #{$i}rem;
    }
    .ms-#{$i}{
        margin-left: #{$i}rem;
        margin-right: #{$i}rem;
    }
    .mv-#{$i}{
        margin-top: #{$i}rem;
        margin-bottom: #{$i}rem;
    }
    
    @include bup(md){
        .rml-#{$i}{
            margin-left: #{$i}rem;
        }
        .rmr-#{$i}{
            margin-right: #{$i}rem;
        }
        .rpl-#{$i}{
            padding-left: #{$i}rem;
        }
        .rpr-#{$i}{
            padding-right: #{$i}rem;
        }
    }
    .pt-#{$i}{
        padding-top: #{$i}rem;
    }
    .pb-#{$i}{
        padding-bottom: #{$i}rem;
    }
    .pl-#{$i}{
        padding-left: #{$i}rem;
    }
    .pr-#{$i}{
        padding-right: #{$i}rem;
    }
    .ps-#{$i}{
        padding-left: #{$i}rem;
        padding-right: #{$i}rem;
    }
    .pv-#{$i}{
        padding-top: #{$i}rem;
        padding-bottom: #{$i}rem;
    }
    .p-#{$i}{
        padding: #{$i}rem;
    }
}
@mixin sfs($fs) {
    $calced-size: $fs / $bfs;
    font-size: #{$calced-size + em};
}
@function rem($pixels) {
    @return #{$pixels/$bfs}rem;
}
@function em($pixels) {
    @return #{$pixels/$bfs}em;
}



.header {
    &-top,
    &-middle,
    &-bottom {
        padding-left: 5%;
        .container {
            margin-left: 0;
        }
        &--left {
            display: inline-flex;
            align-items: center;
        }
    }
    &-top {
        background-color: $violet;
        color: $white;
        position: relative;
        font-size: rem(17);
        display: flex;
        justify-content: space-between;
        .container {
            display: inline-flex;
        }
        .top-contacts {
            display: inline-flex;
            align-items: center;
            color: #E6E2F0;
            li {
                a {
                    padding: 0 1rem;
                    &:hover {
                        color: $yellow;
                    }
                }
                &:after {
                    content: "|";
                }
                &:first-of-type {
                    a {
                        padding-left: 0;
                    }
                }
                &:last-of-type {
                    &:after {
                        content: none;
                    }
                }
            }

        }
        .top-actions {
            display: inline-flex;
            flex: 0 0 auto;
            align-items: center;
        }
        .top-buttons {
            display: flex;
            align-items: flex-start;
            a {
                // min-height: 100%;
                height: 49px;
                display: inline-flex;
                align-items: center;
                padding: 0 1rem;
                font-size: rem(14);
                font-weight: 700;
                &:hover {
                    filter: brightness(110%);
                }
                &:first-child {
                    background-color: #8A74BF;
                }
                &:last-child {
                    background-color: $yellow;
                    color: #254151;
                    i {
                        color: $white;
                        padding-right: rem(8);
                    }
                }
            }
        }
    }
    &-middle {
        @include bbetween(md, hd){
            padding-right: 15px;
        }
        @include bup(hd){
            padding-right: 5%;
        }
        .container {
            align-items: center;
            padding-top: rem(10);
            display: flex;
            justify-content: space-between;
        }
        .top-promo {
            color: #A3A3A3;
            max-width: 330px;
            font-size: rem(15);
            margin-left: rem(38);
            padding-left: rem(38);
            border-left: 4px solid #EBEBEB;
            transform: translateY(rem(-5));
        }
    }
    &-bottom {
        background-color: $green;
        position: relative;
    }
    &-hamburger {
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        padding: 0 5% 0 0;
        margin-left: auto;
        flex-basis: 10%;
        &:focus {
            outline: none;
        }
        @include bup(md){
            display: none;
        }
        svg {
            height: 52px;
            width: auto;
        }
        .line {
            fill: none;
            stroke: $violet;
            stroke-width: 6;
            transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
            stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
            &1 {
                stroke-dasharray: 60 207;
                stroke-width: 6;
            }
            &2 {
                stroke-dasharray: 60 60;
                stroke-width: 6;
            }
            &3 {
                stroke-dasharray: 60 207;
                stroke-width: 6;
            }
        }
        &.opened {
            .line {
                &1 {
                    stroke-dasharray: 90 207;
                    stroke-dashoffset: -134;
                    stroke-width: 6;

                }
                &2 {
                    stroke-dasharray: 1 60;
                    stroke-dashoffset: -30;
                    stroke-width: 6;
                    
                }
                &3 {
                    stroke-dasharray: 90 207;
                    stroke-dashoffset: -134;
                    stroke-width: 6;
                }
            }
        }
    }
    .middle-nav {
        font-size: 13px;
        color: $green;
        font-weight: 700;
        align-self: flex-end;
        a {
            padding: rem(6) rem(15);
            border: 2px solid $green;
            border-radius: rem(8);
            display: inline-block;
            margin-right: 3px;
            &:last-of-type {
                margin-right: 0;
            }
            &:hover {
                background-color: $green;
                color: $white;
            }
        }
    }
    .nav {
        display: flex;
        align-items: center;
        justify-content: space-around;
        a {
            color: $white;
            padding: rem(10) 0;
            display: inline-block;
            font-weight: 700;
            text-transform: uppercase;
            &:hover {
                color: $violet;
            }
        }
    }
    a {
        transition: none;
    }
    @include bup(md) {
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);
        position: sticky;
        top: 0;
        z-index: 30;
        background-color: $white;
        .middle-nav {
            transform: translateY(5px);
            a {
                &:hover,
                &.nav-item-active {
                    background-color: $green;
                    color: $white;
                }
            }
        }
        .nav-container {
            display: block !important;
            align-self: flex-end;
        }
        .nav {
            padding-right: 159px;
            a {
                &:last-of-type {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    padding: rem(10) rem(30);
                    background-color: #808E78;
                }
                &.nav-item-active {
                    position: relative;
                    color: $violet;
                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 5px;
                        background-color: $yellow;
                    }
                }
            }
        }
    }
    @include bup(lg) {
        &-middle--left {
            padding-bottom: 10px;
        }
    }
    @include bdown(shd){
        &-top {
            font-size: rem(14);
            .top-contacts {
                font-size: 12px;
                li {
                    a {
                        padding-left: .5rem;
                        padding-right: .5rem;
                    }
                }
            }
        }
        &-middle {
            &--left {
                flex-basis: 40%;
                .top-promo {
                    margin-left: 1rem;
                    padding-left: 1rem;
                }
            }
            .middle-nav {
                a {
                    font-size: 11px;
                }
            }
        }
    }
    @include bdown(lg){
        &-top,
        &-middle,
        &-bottom {
            padding-left: 2%;
        }
        &-top {
            .top-contacts {
                li {
                    a {
                        span {
                            &:last-child {
                                display: none;
                            }
                            &:nth-last-child(2) {
                                display: none;
                            }
                        }
                        &:before {
                            font-family: $awesome;
                            font-weight: 900;
                            content: "\f095";
                            display: inline-block;
                            color: $yellow;
                            padding-right: 3px;
                        }
                    }
                }
            }
        }
        &-middle {
            &--left {
                flex-basis: 35%;
                .top-promo {
                    font-size: 12px;
                }
            }
        }
        &-bottom {
            font-size: 14px;
        }
    }
    @include bdown(nm){
        &-top,
        &-middle,
        &-bottom {
            padding-left: 1%;
        }
        &-middle {
            .middle-nav {
                a {
                    font-size: 9px;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
        &-bottom {
            font-size: 11px;
        }
    }
    @include bdown(md){
        &-top,
        &-middle,
        &-bottom {
            padding-left: 0;
        }
        .nav-item-active {
            color: $violet !important;
        }
        .nav-container {
            display: none;
        }
        &-top {
            .top-contacts {
                li {
                    display: none;
                    &:nth-child(1){
                        a {
                            &:before {
                                font-size: 20px;
                            }
                            span:nth-child(1){
                                display: none;
                            }
                        }
                        display: list-item;
                        &:after {
                            content: none;
                        }
                    }
                }
            }
        }
        &-middle {
            // max-height: 60px;
            .container {
                flex-wrap: wrap;
                padding-left: 0;
                padding-right: 0;
            }
            .nav-container {
                flex-basis: 100%;
            }
            &--left {
                flex-basis: 75%;
                padding-right: 5%;
                margin-left: 5%;
            }
            .top-promo {
                display: none;
            }
            .middle-nav {
                transform: none;
                a {
                    display: block;
                    margin-right: 0;
                    border: none;
                    text-align: center;
                    font-size: 20px;
                    border-radius: 0;
                    border-bottom: 1px solid $green;
                }
            }
        }
        &-bottom {
            .nav {
                display: block;
                a {
                    display: block;
                    text-align: center;
                    font-size: 20px;
                    border-bottom: 1px solid $white;
                }
            }
            .container {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
.loaded header a {
    transition: all .4s ease-in-out;
}
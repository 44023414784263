section {
    padding: 3rem 0;
}
.container {
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: $box;
    &.super-slim {
        max-width: 730px;
    }
    &.slim {
        max-width: 900px;
    }
    &.narrow {
        max-width: 1080px;
    }
    &.wider {
        max-width: 1500px;
    }
    &.wide {
        max-width: 1600px;
    }
    &.widest {
        max-width: 1875px;
    }
    &.fw {
        max-width: none !important;
        padding: 0;
        .row {
            margin-left: auto;
            margin-right: auto;
            .row {
                @include bup(md){
                    margin-left: -15px;
                    margin-right: -15px;
                }
            }
        }
    }
    @include bdown(nm) {
        padding: 0 3%;
        &.fw {
            .box {
                padding: 0 3%;
            }
        }
    }
    @include bdown(md) {
        padding: 0 5%;
        &.fw {
            .box {
                padding: 0 5%;
            }
        }
    }
}
.row {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    &.reversed {
        flex-direction: row-reverse;
    }
    &.vcenter {
        align-items: center;
    }
    &.no-pad {
        padding: 0;
    }
    &.no-mar {
        margin: 0;
    }
    &.block {
        display: block;
        margin-left: 0;
        margin-right: 0;
    }
    @include bdown(md) {
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        &.eq-h {
            height: auto !important;
        }
    }
    &.no-collapse {
        @include bdown(md) {
            display: flex;
            .col {
                margin-bottom: 0;
                &[class^="basis"],
                &[class*="basis"]{
                    flex-grow: 0;
                }
            }
        }
    }
}
.col {
    padding: 0 15px;
    flex: 1 0 0;
    box-sizing: border-box;
    @include bup(md){
        &[class^="basis"],
        &[class*="basis"]{
            flex-grow: 0;
        }
    }
    @include bdown(md) {
        margin-bottom: 20px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
.masonry {
    &-inner {
        border: 1px solid $gray;
        height: 100%;
        padding: 1rem;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
        display: block;
    }
    a.masonry-inner:hover {
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    }
    &-inverted {
        .masonry-inner {
            box-shadow: none;
        }
    }
    & > div > a {
        display: block;
        height: 100%;
    }
}
.resource {
    &s {
        padding-top: 0;
        & > .container > .row {
            @include bdown(md) {
                display: flex;
                flex-direction: column;
            }
            &.reverse {
                flex-direction: column-reverse;
            }
        }
    }
    &-main {
        .img-wrapper {
            img {
                display: block;
                width: 100%;
            }
        }
        @include bup(md) {
            padding-right: calc(10% + 15px);
        }
        &.alt {
            .item {
                @include bup(md) {
                    display: flex;
                    .media-wrapper {
                        padding-bottom: 32.25%;
                    }
                }
                .img-wrapper,
                .media-wrapper {
                    width: 100%;
                    flex: 0 0 50%;
                }
                .event-info {
                    flex: 0 0 50%;
                }
            }
        }
    }
    &-info {
        background-color: #fdfdfa;
        margin-top: rem(-60);
        position: relative;
        z-index: 5;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0 3px 16px rgba($color: #000000, $alpha: 0.16);
        padding: rem(60) 2rem 2rem 2rem;
        .date {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0.5rem 1rem;
            background-color: $green;
            color: $white;
            font-weight: 500;
            font-size: 14px;
        }
    }
    &-title {
        font-size: rem(28);
        a {
            @include transition;
            color: inherit;
            &:hover {
                color: $green;
            }
        }
    }
    &-details {
        display: flex;
        font-size: 10px;
        color: $violet-light;
        margin-bottom: 1rem;
        i {
            margin-right: 0.25rem;
            color: $green;
        }
        @include bdown(md) {
            flex-direction: column;
        }
    }
    &-author {
        font-weight: 700;
        margin-right: 3rem;
        @include bdown(md) {
            margin-bottom: 0.5rem;
        }
    }
    &-category {
        @include bup(md) {
            margin-right: 0.5rem;
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                color: $green;
            }
        }
    }
    &-aside {
        &-title {
            font-family: $ak;
            color: $violet;
        }
        &-list {
            li {
                margin-bottom: 1rem;
            }
            a {
                display: block;
                color: $base-alt;
                font-weight: 700;
                text-transform: uppercase;
                border: 1px solid #e3e3e3;
                padding: 1rem;
                font-size: rem(16);
                border-radius: rem(10);
                position: relative;
                &:hover,
                &.active {
                    background-color: $violet;
                    color: $white;
                    &:after {
                        color: $white;
                    }
                }
                &:after {
                    content: "\f105";
                    display: block;
                    position: absolute;
                    right: 1rem;
                    top: 50%;
                    transform: translateY(-50%);
                    color: $base;
                    font-weight: 700;
                    font-family: $awesome;
                }
            }
        }
        h3 {
            font-size: rem(27);
        }
        &-pp {
            li {
                margin-bottom: 1rem;
            }
            a {
                display: flex;
                align-items: center;
                padding: 1rem;
                border: 1px solid #e3e3e3;
                padding: 1rem;
                border-radius: rem(10);
                @include transition;
                &:hover {
                    background-color: $beige;
                }
                img {
                    width: 75px;
                    height: 70px;
                    object-fit: cover;
                    margin-right: 1rem;
                    border-radius: 5px;
                }
                h6 {
                    font-weight: 400;
                    color: $violet;
                    font-size: rem(16);
                }
                .date {
                    color: $green;
                    font-size: 12px;
                    i {
                        font-size: 14px;
                        padding-right: rem(8);
                    }
                }
            }
        }
        &-banner {
            background-color: $violet;
            color: $white;
            img {
                width: 100%;
                display: block;
            }
            h4 {
                color: inherit;
            }
            .inner {
                padding: 1rem 2rem 0.5rem;
            }
        }
        @include bdown(md) {
            padding-top: 2rem;
        }
    }
}
.row.reversed {
    .resource-main {
        @include bup(md) {
            padding-right: 15px;
            padding-left: calc(10% + 15px);
        }
    }
}
.event {
    &-info {
        background-color: #f8faf4;
        box-shadow: 2px 3px 16px rgba($color: #000000, $alpha: 0.08);
        padding: 0 2rem 0.5rem;
        &-top {
            display: flex;
            position: relative;
            left: -2rem;
            width: calc(100% + 4rem);
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 2rem;
            .date {
                min-width: 50%;
                margin-right: 5%;
                background-color: $violet;
                color: $white;
                font-size: rem(20);
                font-weight: 700;
                padding: 0.5rem 2rem;
                border-bottom-right-radius: rem(20);
                i {
                    margin-right: 0.5rem;
                    font-size: 1.2em;
                }
            }
            .event-category {
                font-size: 13px;
                font-weight: 700;
                color: $white;
                background-color: $green;
                padding: 0.5rem 2rem;
                border-bottom-left-radius: rem(20);
                @include transition;
                &:hover {
                    background-color: $violet;
                }
            }
            @include bdown(md) {
                flex-direction: column;
                align-items: stretch;
                .date {
                    border-bottom-right-radius: 0;
                    width: auto;
                    margin-right: 0;
                    text-align: center;
                }
                .event-category {
                    border-bottom-left-radius: 0;
                    text-align: center;
                }
            }
        }
    }
    &-title {
        font-size: rem(33);
        color: $violet;
        a {
            @include transition;
            color: inherit;
            &:hover {
                color: $green;
            }
        }
    }
    &-excerpt {
        font-size: rem(16);
        @include rlh(28px);
    }
}
.news {
    .resource-main {
        @include bup(md) {
            padding-right: 15px;
        }
    }
    .media-wraper-outer {
        flex-grow: 1;
        width: 100%;
    }
    .item {
        display: flex;
        align-items: flex-start;
        @include bdown(md) {
            flex-direction: column;
        }
    }
    &-info {
        background-color: #f8faf4;
        box-shadow: 2px 3px 16px rgba($color: #000000, $alpha: 0.08);
        padding: 2rem 2rem 0.5rem rem(80);
        flex: 0 0 60%;
        position: relative;
        @include bup(md) {
            border-right: solid $violet rem(35);
        }
        .date {
            position: absolute;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: rem(128);
            height: rem(128);
            top: 100%;
            left: 0;
            transform: translate(-50%, -100%);
            background-color: $violet;
            color: $white;
            font-size: rem(20);
            font-weight: 700;
            border: 8px solid #f8faf4;
            border-radius: 50%;
            span {
                display: block;
            }
            i {
                margin-right: 0.5rem;
                font-size: 1.2em;
            }
            @include bdown(md) {
                position: static;
                margin-bottom: -40px;
                transform: translate(-50%, -40%);
            }
        }
    }
    &-title {
        font-size: rem(33);
        color: $violet;
        a {
            @include transition;
            color: inherit;
            &:hover {
                color: $green;
            }
        }
    }
    &-category {
        font-family: $karla;
        font-size: rem(19);
        color: $violet;
        margin-bottom: 5px;
    }
    &-excerpt,
    &-text {
        font-size: rem(16);
        @include rlh(28px);
    }
    &.single {
        .item {
            display: block;
        }
        .news-info {
            padding: 2rem 2rem 2rem 4rem;
        }
        .date {
            top: 0;
            transform: initial;
            transform: translateX(-50%);
        }
        .media-wraper-outer,
        .img-wrapper {
            margin-left: -3rem;
            width: calc(100% + 6rem);
            margin-top: 2rem;
            margin-bottom: 2rem;
            @include bup(md) {
                margin-left: calc(-3rem - 18.5px);
                width: calc(100% + 6rem + 35px);
            }
        }
    }
}
.read-more {
    font-size: rem(16);
    font-family: $karla;
    color: $violet;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    @include transition;
    &:hover {
        color: $green;
    }
    i {
        margin-right: 0.5rem;
        color: $green;
    }
}
.pagination {
    display: inline-flex;
    align-items: center;
    @include bdown(md) {
        display: flex;
        justify-content: space-between;
    }
    .pagination-list {
        flex-grow: 1;
        display: flex;
        justify-content: space-around;
        margin-left: 0;
        margin-bottom: 0;
        li {
            display: inline-block;
            margin-right: 7px;
            a {
                color: $black;
                text-decoration: none;
                background-color: $beige;
                text-align: center;
                padding: 14px 20px;
                font-weight: 300;
                // border: 1px solid #C8C8C8;
                // border-radius: 3px;
                @include font-count(12, 20);
                &:hover,
                &.active-pag {
                    background-color: $green;
                    color: #434e6e;
                    // border-color: $red;
                }
            }
        }
        @include bdown(md) {
            li {
                margin: 0 1px;
                a {
                    min-width: 0;
                    padding: 9px 15px;
                }
            }
        }
        @include bdown(xs) {
            li a {
                padding: 9px;
            }
        }
    }
    .pag-arrow {
        border-radius: 5px;
        font-size: rem(24);
        // border: 2px solid transparent;
        flex-shrink: 0;
        display: flex;
        align-content: center;
        text-decoration: none;
        background-color: $beige;
        text-align: center;
        padding: 14px 20px;
        &:first-of-type {
            margin-right: 20px;
        }
        &:last-of-type {
            margin-left: 20px;
        }
        @include bdown(md) {
            &:first-of-type {
                margin-right: 7px;
            }
            &:last-of-type {
                margin-left: 7px;
            }
        }
        @include bdown(xs) {
            min-width: 30px;
            padding: 5px;
            &:first-of-type {
                margin-right: 5px;
            }
            &:last-of-type {
                margin-left: 5px;
            }
        }
    }
    &.single-pagination {
        display: flex;
        .pagination-list {
            justify-content: space-between;
        }
    }
}
.soc-share {
    display: inline-flex;
    align-items: center;
    &-title {
        font-family: $ak;
        color: $violet-light;
        font-size: rem(31);
        padding-right: 1rem;
    }
    &-container {
        display: flex;
        justify-content: flex-end;
    }
    li {
        margin-right: rem(8);
        &:last-of-type {
            margin-right: 0;
        }
    }
    a {
        @include transition;
        &:hover {
            filter: brightness(110%);
        }
    }
    i {
        height: rem(33);
        width: rem(33);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f8faf4;
    }
    .fa-facebook-f {
        background-color: #3b5998;
    }
    .fa-twitter {
        background-color: #7ed0e2;
    }
    .fa-linkedin-in {
        background-color: #486aae;
    }
    .fa-pinterest-p {
        background-color: #de220b;
    }
    .fa-instagram {
        background-color: #506897;
    }
    .fa-envelope {
        background-color: #6f7173;
    }
}
.resources {
    .article-navigation {
        .row {
            @include bdown(md) {
                display: flex;
                flex-direction: row;
            }
        }
    }
}

form {
	position: relative;
	margin-top: 0;
	margin-bottom: 0;
	.thankyou-url {
		display: none;
	}
	input,
	select,
	textarea {
		&.has-danger {
			border-bottom: 1px solid #f00 !important;
			// border-color: #f00 !important;
		}
	}
	.input-wrapper {
		&.has-danger {
			border-color: #f00 !important;
			&:before,
			&:after {
				background-color: #f00 !important;
			}
		}
	}
	input[type="radio"].has-danger,
	input[type="checkbox"].has-danger {
		& + label {
			&:before {
				border-color: #f00 !important;
			}
		}
	}
	::placeholder {
		color: #707070;
	}
	input,
	select,
	textarea {
		font-size: rem(20);
		color: $base;
	}
	input,
	textarea,
	select,
	.select-wrapper {
		width: 100%;
		border: 1px solid $gray;
	}
	input[type="submit"] {
		width: auto;
		border: none;
		cursor: pointer;
	}
	input {
		padding: 0.5em 1em;
	}
	.select-wrapper {
		height: 2.63rem;
		position: relative;
		@include font-count(15, 18);
		&:before {
			position: absolute;
			right: 1em;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1.5em;
			z-index: 10;
			pointer-events: none;
		}
		&:after {
			position: absolute;
			display: block;
			content: "";
			right: 0;
			top: 0;
			width: 1.6em;
			height: 100%;
			font-size: 2em;
			z-index: 5;
			pointer-events: none;
		}
	}
	select {
		padding: 0.5em 1em;
		width: 100%;
		border-radius: 0;
		&:disabled {
			color: $gray;
		}
		option [disabled="disabled"] {
			color: $gray;
		}
	}
	textarea {
		padding: 1.5em;
		font-family: inherit;
		// margin-bottom: 1em;
	}
	label {
		text-align: left;
		display: block;
		padding-bottom: 0.2em;
		&.file_upload {
			display: inline-flex;
			align-items: flex-start;
			input[type="file"] {
				position: absolute;
				visibility: hidden;
				opacity: 0;
				display: none;
			}
			mark {
				background-color: transparent;
				color: #fff;
				opacity: 1;
				font-size: 1rem;
				margin-left: 0.5em;
				@include transition;
			}
			.btn {
				cursor: pointer;
				&.empty {
					color: #fff;
				}
				&:not(.empty):hover {
					& + mark {
						opacity: 0;
					}
				}
			}
		}
	}
	button {
		border: none;
		background-color: transparent;
		font-size: rem(20);
	}
	.row {
		padding-top: 0px;
		padding-bottom: 0px;
		.col {
			&.file-upload-container {
				text-align: left;
				&.has-danger {
					border: 1px solid #f00;
					mark {
						color: #f00;
					}
				}
			}
			@include bdown(md) {
				margin-bottom: 15px;
			}
		}
	}
	.checkbox input {
		display: none;
	}
	.checkbox label {
		position: relative;
		padding-left: 4em;
		line-height: 3em;
		cursor: pointer;
		&:before {
			content: "";
			position: absolute;
			display: block;
			left: 0;
			top: 0;
			width: 3em;
			height: 3em;
			background-color: #fff;
		}
		&:after {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			padding-left: 0.4em;
			content: "";
			@include font-count(30, 48);
		}
	}

	.checkbox input:checked + label {
		&:after {
			content: "V";
		}
	}

	&.search-form {
		display: inline-block;
		position: relative;
		input::placeholder {
			color: $beige;
		}
		input {
			color: $white;
			background-color: transparent;
			// padding-right: 3rem;
			border: none;
			border-bottom: 1px solid #9f92c4;
			font-size: inherit;
			font-family: inherit;
			max-width: 220px;
			@include bdown(sm) {
				max-width: 145px;
			}
		}
		button {
			position: absolute;
			font-family: $awesome;
			font-size: inherit;
			color: #9f92c4;
			right: 0;
			font-size: rem(25);
			content: "\f002";
			top: 50%;
			transform: translateY(-50%);
			font-weight: 900;
			background-color: initial;
			border: none;
		}
		@include bup(md) {
			margin-left: 1rem;
			input {
				max-width: 220px;
				padding-left: 0;
				padding-right: 2rem;
			}
		}
	}
	&.subscribe-form {
		display: inline-block;
		position: relative;
		width: 100%;
		input {
			padding-right: 3rem;
			font-family: inherit;
			border: none;
			background-color: $white;
			min-height: rem(60);
		}
		button {
			position: absolute;
			right: 1rem;
			top: 50%;
			transform: translateY(-50%);
			color: $violet;
		}
	}
	&.resources-form {
		display: inline-block;
		position: relative;
		width: 100%;
		max-width: 370px;
		input {
			padding-right: 3rem;
			font-family: inherit;
			border: 2px solid #eaeaea;
			background-color: $white;
			min-height: rem(60);
		}
		button {
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: rem(60);
			background-color: $green;
			color: $base;
			&:hover {
				color: $white;
			}
		}
	}
	&.contact-request-form {
		input {
			border-color: $violet-light;
			border-width: 3px;
		}
	}
}

.contact-form {
	textarea {
		padding: 1.5rem 3rem 1.5rem 1.5rem;
	}
	&-wrapper {
		position: relative;

		input {
			min-height: rem(70);
			margin-bottom: 0;
			padding-right: 2.5rem;
			border-color:  $green;
			&::placeholder {
				color: $base-alt;
			}
		}
		select {
			min-height: rem(70);
			margin-bottom: 0;
			border-color: $green;
			color: $base-alt;
			appearance: none;
			&:focus-visible {
				outline: none;
			}
		}
	}
	&-icon {
		position: absolute;
		right: 1.5rem;
		top: 50%;
		transform: translateY(-50%);
		color: $green;
		line-height: 1.5;
		i {
			font-size: rem(16);
		}
	}
	&-select-icon {
		position: absolute;
		right: 1.5rem;
		top: 50%;
		transform: translateY(-50%);
		color: $green;
		pointer-events: none;
		line-height: 1.5;
		i {
			font-size: rem(16);
		}
	}

	&-area {
		position: absolute;
		right: 2.5rem;
		top: 1.5rem;
		color: $green;
		i {
			font-size: rem(16);
		}
	}
}

.temp {
    .top-photo {
        padding: 0;
        position: relative;
        .main-photo {
            img {
                width: 100%;
            }
        }
        .date {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            background-color: $violet;
            color: $white;
            min-width: 50%;
            padding: 0.7rem 3rem;
            display: flex;
            justify-content: space-between;
            border-bottom-right-radius: 10px;
            font-family: $sen;
            // font-size: rem(29);
            @include font-count(12, 29);
            span {
                display: flex;
                align-items: center;
            }
            @include bdown(md) {
                padding: 0.25rem 0.5rem;
            }
        }
    }

    .holding {
        &-header {
            text-align: center;
            margin-bottom: 2rem;
        }
        .text-container {
            text-align: center;
            font-family: $karla;
            h5 {
                line-height: 1.5;
                color: $black;
            }
            p {
                font-size: 19px;
                font-family: $sen;
                color: $base;
            }
        }
        .image-container {
            text-align: center;
            img {
                margin-bottom: 2rem;
            }
            p {
                font-size: 12px;
            }
        }
        .btn-flexbox {
            display: flex;
            justify-content: center;
            column-gap: 2rem;
            margin-top: 1rem;
            @include bdown(xs) {
                display: block;
                text-align: center;
                .btn {
                    margin-bottom: 1rem;
                }
            }
        }
    }
    .more-details {
        padding: 0;
        padding-top: 1rem;
        h4 {
            text-align: center;
        }
        &-text {
            text-align: center;
            font-size: 16px;
        }
        .alt-title {
            text-align: center;
        }

        .btn {
            margin-top: 1rem;
            margin-bottom: 2rem;
        }
        img {
            width: 100%;
        }
    }
    .event-details {
        h4 {
            color: $violet;
            text-align: center;
        }
        background-color: $olive;
        .row {
            margin-bottom: 2rem;
            text-align: center;
            .event-info {
                box-shadow: none;
                .event-header {
                    padding-top: 1rem;
                    h4 {
                        text-align: center;
                    }
                }
            }
            @include bdown(md) {
                text-align: center;
                .event-info {
                    .event-header {
                        h4 {
                            text-align: center;
                        }
                    }
                }
                p {
                    text-align: center;
                }
            }
        }
       
    }
    .another-more-text {
        text-align: center;
    }
}

//Tools
.d {
	&-flex {
		display: flex;
	}
	&-inline-flex {
		display: inline-flex;
	}
	&-block {
		display: block;
	}
	&-inline-block {
		display: inline-block;
	}
	&-inline {
		display: inline;
	}
	&-r {
		@include bup(md) {
			&-flex {
				display: flex;
			}
			&-inline-flex {
				display: inline-flex;
			}
			&-block {
				display: block;
			}
			&-inline-block {
				display: inline-block;
			}
			&-inline {
				display: inline;
			}
		}
	}
}
.p {
	&-static {
		position: static;
	}
	&-relative {
		position: relative;
	}
	&-absolute {
		position: absolute;
	}
	&-sticky {
		position: sticky;
		&-top {
			top: 0;
		}
	}
}
.border {
	border-width: 0;
	border-style: solid;
	border-color: $black;

	&-all {
		&-width {
			@for $i from 0 through 10 {
				&--#{$i} {
					border-width: #{$i}px;
				}
			}
		}
	}
	&-top {
		&-width {
			@for $i from 0 through 10 {
				&-#{$i} {
					border-top-width: #{$i}px;
				}
			}
		}
	}
	&-bottom {
		&-width {
			@for $i from 0 through 10 {
				&--#{$i} {
					border-bottom-width: #{$i}px;
				}
			}
		}
	}
	&-left {
		&-width {
			@for $i from 0 through 10 {
				&-#{$i} {
					border-left-width: #{$i}px;
				}
			}
		}
	}
	&-right {
		&-width {
			@for $i from 0 through 10 {
				&-#{$i} {
					border-right-width: #{$i}px;
				}
			}
		}
	}
	&-color {
		&--white {
			border-color: $white;
		}
		&--beige {
			border-color: $beige;
		}
		&--gray {
			border-color: $gray;
		}
		&--black {
			border-color: $black;
		}
	}
	&-style {
		&-dotted {
			border-style: dotted;
		}
		&-dashed {
			border-style: dashed;
		}
		&-solid {
			border-style: solid;
		}
		&-double {
			border-top-style: double;
			border-bottom-style: double;
			border-left-style: double;
			border-right-style: double;
		}
	}
	&-radius {
		&-4 {
			border-radius: 4px;
		}
		&-5 {
			border-radius: 5px;
		}
		&-6 {
			border-radius: 6px;
		}
		&-7 {
			border-radius: 7px;
		}
		&-8 {
			border-radius: 8px;
		}
		&-9 {
			border-radius: 9px;
		}
		&-10 {
			border-radius: 10px;
		}
		&-rounded {
			border-radius: 50%;
		}
	}
}
.flex {
	&-aic {
		align-items: center;
	}
	&-ait {
		align-items: flex-start;
	}
	&-aib {
		align-items: flex-end;
	}
	&-jcs {
		justify-content: flex-start;
	}
	&-jce {
		justify-content: flex-end;
	}
	&-jcc {
		justify-content: center;
	}
	&-jcsb {
		justify-content: space-between;
	}
	&-jcsa {
		justify-content: space-around;
	}
}
.text {
	&-left {
		text-align: left;
	}
	&-center {
		text-align: center;
	}
	&-right {
		text-align: right;
	}
	&-uc {
		text-transform: uppercase;
	}
	&-lc {
		text-transform: lowercase;
	}
	&-cz {
		text-transform: capitalize;
	}
	&-r {
		@include bup(md) {
			&-left {
				text-align: left;
			}
			&-center {
				text-align: center;
			}
			&-right {
				text-align: right;
			}
		}
	}
}
.float {
	&-container {
		&:after {
			content: "";
			display: block;
			clear: both;
		}
	}
	&-left {
		float: left;
	}
	&-right {
		float: right;
	}
}
.font-size {
	&-xs {
		font-size: 60%;
	}
	&-sm {
		font-size: 80%;
	}
	&-l {
		font-size: 125%;
	}
	&-xl {
		font-size: 150%;
	}
}
.line-height {
	&-inherit {
		line-height: inherit;
	}
	&-normal {
		line-height: normal;
	}
	&-60 {
		line-height: 60%;
	}
	&-80 {
		line-height: 80%;
	}
	&-100 {
		line-height: 100%;
	}
	&-125 {
		line-height: 125%;
	}
	&-150 {
		line-height: 150%;
	}
	&-200 {
		line-height: 200%;
	}
}
.letter-spacing {
	&-min {
		letter-spacing: -0.1em;
	}
	&-condenced {
		letter-spacing: -0.05em;
	}
	&-wider {
		letter-spacing: 0.05em;
	}
	&-max {
		letter-spacing: 0.1em;
	}
}
.hidden {
	display: none !important;
	&-mobile {
		@include bdown(md) {
			display: none !important;
		}
	}
	&-desktop {
		@include bup(md) {
			display: none !important;
		}
	}
}
.visibility {
	&-on {
		visibility: visible;
	}
	&-off {
		visibility: hidden;
	}
}
.overflow {
	&-visible {
		overflow: visible;
	}
	&-scroll {
		overflow: scroll;
	}
	&-hidden {
		overflow: hidden;
	}
}
.m {
	&-auto {
		margin-left: auto;
		margin-right: auto;
	}
	&l-auto {
		margin-left: auto;
	}
	&r-auto {
		margin-right: auto;
	}
	&-dt-auto {
		@include bup(md) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	&l-dt-auto {
		@include bup(md) {
			margin-left: auto;
		}
	}
	&r-dt-auto {
		@include bup(md) {
			margin-right: auto;
		}
	}
	&-ls-auto {
		@include bup(ls) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	&l-ls-auto {
		@include bup(ls) {
			margin-left: auto;
		}
	}
	&r-ls-auto {
		@include bup(ls) {
			margin-right: auto;
		}
	}
	&-mh-auto {
		@include bbetween(xs, md) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	&l-mh-auto {
		@include bbetween(xs, md) {
			margin-left: auto;
		}
	}
	&r-mh-auto {
		@include bbetween(xs, md) {
			margin-right: auto;
		}
	}

	&-mv-auto {
		@include bdown(xs) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	&l-mv-auto {
		@include bdown(xs) {
			margin-left: auto;
		}
	}
	&r-mv-auto {
		@include bdown(xs) {
			margin-right: auto;
		}
	}
}
.no-bg {
	background-image: none !important;
}
.underline {
	text-decoration: underline;
}
.shadow {
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	&-small {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}
	&-big {
		box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
	}
}
.bold {
	font-weight: 700;
}
.italic {
	font-style: italic;
}

.media-wrapper {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	iframe {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
	}
}
.object-fit {
	&-cover {
		object-fit: cover;
	}
	&-contain {
		object-fit: contain;
	}
}
.clearfix {
	&:after {
		display: table;
		content: "";
		clear: both;
	}
}
.white-space {
	&-nowrap {
		white-space: nowrap;
	}
	&-normal {
		white-space: normal;
	}
	&-pre {
		white-space: pre;
	}
}
.word-break {
	&-normal {
		word-break: normal;
	}
	&-all {
		word-break: break-all;
	}
	&-word {
		word-break: break-word;
	}
}
.acc {
	&-content {
		display: none;
	}
	.btn-acc {
		span {
			&:first-child {
				display: inline-block;
			}
			&:last-child {
				display: none;
			}
		}
	}
	&-opened {
		.acc-content {
			display: block;
		}
		.btn-acc {
			span {
				&:first-child {
					display: none;
				}
				&:last-child {
					display: inline-block;
				}
			}
		}
	}
}
.banner {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	&-fixed {
		@include bup(md) {
			background-attachment: fixed;
		}
	}
}
.opacity-panel {
	position: relative;
	&-bg {
		position: absolute;
		display: block;
		content: "";
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
	}
	&-content {
		position: relative;
		z-index: 5;
	}
}
.img-cover {
	object-fit: cover;
	font-family: "object-fit: cover;";
	width: 100%;
	height: 100%;
}
@for $i from 0 through 10 {
	$m: $i * 0.1;
	.op-#{$i} {
		opacity: $m;
	}
}
